import { Box, Flex, Tabs } from '@radix-ui/themes';
import { useParams } from 'react-router-dom';

import EmployeeForm from 'modules/config/components/EmployeeForm';
import UserAccessForm from 'modules/config/components/UserAccessForm';

import Container from 'components/Container';
import IconLoading from 'components/IconLoading';

import { useEmployee } from 'services/staffs';
import { EmployeeResponse } from 'services/staffs/types';

const EmployeeDetail = () => {
  const { id } = useParams();
  const { data, isLoading } = useEmployee(id as string, {
    enabled: !!id,
  });

  return (
    <Container>
      {isLoading ? (
        <Flex align="center" justify="center" style={{ height: 300 }}>
          <IconLoading width="48" height="48" />
        </Flex>
      ) : (
        <Tabs.Root defaultValue="account">
          <Tabs.List>
            <Tabs.Trigger value="account">Dados principais</Tabs.Trigger>
            <Tabs.Trigger value="access" disabled={!data?.id}>
              Controle de acesso
            </Tabs.Trigger>
          </Tabs.List>

          <Box style={{ maxWidth: 800 }} pt="6">
            <Tabs.Content value="account">
              <EmployeeForm data={data} />
            </Tabs.Content>

            <Tabs.Content value="access">
              <UserAccessForm data={data as EmployeeResponse} />
            </Tabs.Content>
          </Box>
        </Tabs.Root>
      )}
    </Container>
  );
};

export default EmployeeDetail;
