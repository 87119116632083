import { useCallback, useState } from 'react';

import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import { IconButton, Flex, Text, Select } from '@radix-ui/themes';

import { PaginationProps } from './types';

const Pagination = ({ num_pages, onChange }: PaginationProps) => {
  const [page, setPage] = useState<number>(1);

  const onChangePage = useCallback(
    (value: number) => {
      setPage(value);
      onChange?.(value);
    },
    [onChange],
  );

  return (
    <Flex align="center" gap="2" mt="4" justify="between">
      <Flex gap="2">
        <Text>Ir para a página</Text>

        <Select.Root
          defaultValue="1"
          value={page.toString()}
          onValueChange={(value) => onChangePage(Number(value))}
        >
          <Select.Trigger />

          <Select.Content>
            {Array(num_pages)
              .fill('')
              .map((_, index) => (
                <Select.Item
                  key={`pagination_${index + 1}`}
                  value={(index + 1).toString()}
                >
                  {index + 1}
                </Select.Item>
              ))}
          </Select.Content>
        </Select.Root>
      </Flex>

      <Flex gap="2">
        <IconButton
          variant="soft"
          disabled={page === 1}
          onClick={() => {
            onChangePage(Math.max(page - 1, 0));
          }}
        >
          <ChevronLeftIcon width="18" height="18" />
        </IconButton>

        <IconButton
          variant="soft"
          disabled={page === num_pages}
          onClick={() => {
            onChangePage(page === num_pages ? page : page + 1);
          }}
        >
          <ChevronRightIcon width="18" height="18" />
        </IconButton>
      </Flex>
    </Flex>
  );
};

export default Pagination;
