import { AxiosError } from 'axios';
import type {
  AxiosResponse,
  AxiosInstance,
  InternalAxiosRequestConfig,
} from 'axios';

import { CookieKey, getCookie, removeCookie } from 'utils/cookies';

const onResponse = (response: AxiosResponse) => response;
const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  const errorCode = error.status ?? error.request?.status;

  if (
    errorCode === 401 ||
    errorCode === 403 ||
    error.code === AxiosError.ERR_NETWORK
  ) {
    removeCookie(CookieKey.AuthToken);
    return Promise.reject((window.location.href = '/'));
  }

  return Promise.reject(error);
};

const onRequest = async (config: InternalAxiosRequestConfig) => {
  const token = getCookie(CookieKey.AuthToken, document.cookie);

  const { headers } = config;

  if (headers && (headers.Authorization || token)) {
    headers.Authorization = `Bearer ${headers.Authorization ?? token}`;
  }

  return { ...config, headers };
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

export const setupInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
};
