import { UseMutationOptions, useMutation } from '@tanstack/react-query';

import { ApiError, ApiResponse } from 'entities/Response';

import { baseApi } from 'libs/axios';

import parseData from 'utils/parseData';

import { AttachmentsData, AttachmentsResponse } from './types';

export const useUpdateAttachments = (
  options?: UseMutationOptions<AttachmentsResponse, ApiError, AttachmentsData>,
) => {
  return useMutation<AttachmentsResponse, ApiError, AttachmentsData>({
    mutationFn: (data) =>
      baseApi
        .patch<ApiResponse<AttachmentsResponse>>(
          `attachments/${data.id}`,
          data.formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        .then(parseData),
    ...options,
  });
};
