import { forwardRef } from 'react';

import { Box, TextArea } from '@radix-ui/themes';

import InputError from 'components/InputError';
import InputLabel from 'components/InputLabel';

import { TextAreaFieldProps } from './types';

const TextAreaField = forwardRef<HTMLTextAreaElement, TextAreaFieldProps>(
  (props, ref) => {
    const { label, error } = props;

    return (
      <Box mb="5">
        {label && <InputLabel text={label} />}

        <TextArea
          ref={ref}
          size="3"
          color={error ? 'red' : 'blue'}
          {...props}
        />

        {error && <InputError error={error} />}
      </Box>
    );
  },
);

TextAreaField.displayName = 'TextAreaField';

export default TextAreaField;
