import { Box } from '@radix-ui/themes';

import { ContainerProps } from './types';

const Container = ({ children }: ContainerProps) => {
  return (
    <Box
      p="4"
      style={{
        background: 'var(--accent-1)',
        borderRadius: 'var(--radius-2)',
      }}
    >
      {children}
    </Box>
  );
};

export default Container;
