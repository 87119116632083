import type {
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';

import type { ApiError, ApiResponse } from 'entities/Response';

import { baseApi } from 'libs/axios';

import parseData from 'utils/parseData';

import { createUseCurrentUser, createUseSystemUser } from './keys';
import type { SystemUserResponse, SystemUserData, UserResponse } from './types';

export const useCurrentUser = (
  options?: UseQueryOptions<UserResponse, ApiError>,
) => {
  return useQuery({
    queryKey: createUseCurrentUser(),
    queryFn: () =>
      baseApi.get<ApiResponse<UserResponse>>(`auth/me`).then(parseData),
    refetchInterval: false,
    retry: 3,
    staleTime: Infinity,
    ...options,
  });
};

export const useSystemUser = (
  userId: string,
  options?: Partial<UseQueryOptions<SystemUserResponse, ApiError>>,
) => {
  return useQuery({
    queryKey: createUseSystemUser(userId),
    queryFn: () =>
      baseApi
        .get<ApiResponse<SystemUserResponse>>(`users/${userId}`)
        .then(parseData),
    ...options,
  });
};

export const useUpdateSystemUser = (
  userId?: number,
  options?: UseMutationOptions<SystemUserResponse, ApiError, SystemUserData>,
) => {
  return useMutation({
    mutationFn: (data) =>
      baseApi
        .patch<ApiResponse<SystemUserResponse>>(`users/${userId}`, data)
        .then(parseData),
    ...options,
  });
};

export const useCreateSystemUser = (
  options?: UseMutationOptions<
    SystemUserResponse,
    ApiError,
    Omit<SystemUserData, 'id'>
  >,
) => {
  return useMutation({
    mutationFn: (data) =>
      baseApi
        .post<ApiResponse<SystemUserResponse>>(`users`, data)
        .then(parseData),
    ...options,
  });
};

export const useDeleteystemUser = (
  userId?: number,
  options?: UseMutationOptions<undefined, ApiError, undefined>,
) => {
  return useMutation({
    mutationFn: (data) =>
      baseApi.delete(`users/${userId}`, data).then(parseData),
    ...options,
  });
};
