import { Box, Flex, Heading, Separator } from '@radix-ui/themes';
import { useParams } from 'react-router-dom';

import Mail from 'modules/tasks/components/Mail';
import Recipients from 'modules/tasks/components/Recipients';
import StatusColor from 'modules/tasks/components/StatusColor';

import Container from 'components/Container';
import IconLoading from 'components/IconLoading';
import InputField from 'components/InputField';

import { useCompany } from 'services/companies';
import { useTaskDetail } from 'services/tasks';

import { formatDate } from 'utils/date';

const TaskDetail = () => {
  const { id } = useParams();

  const taskId = Number(id ?? 0);

  const { data: task, isPending: isPendingTask } = useTaskDetail(taskId);

  const { data: company, isPending: isPendingCompany } = useCompany(
    task?.company_id as string,
    {
      enabled: !!task?.company_id,
    },
  );

  if (isPendingTask || isPendingCompany) {
    return (
      <Flex align="center" justify="center" style={{ minHeight: 400 }}>
        <IconLoading />
      </Flex>
    );
  }

  return (
    <Flex gap="4">
      <Box style={{ flex: 1 }}>
        <Container>
          <Flex py="4" gap="4" align="center">
            <Heading size="4" color="gray">
              {task?.communication_purpose.name}
            </Heading>

            {task?.status && (
              <StatusColor
                status={task.status}
                text={task.status}
                isCreated={false}
              />
            )}
          </Flex>

          <Separator my="3" size="4" />

          <Box style={{ maxWidth: 800 }} pt="4">
            <InputField
              label="Cliente"
              value={company?.trading_name}
              readOnly
            />
            <InputField
              label="Mês referência"
              value={formatDate(
                new Date(task?.reference_date.replace('-', '/') as string),
                'MM/yyyy',
              )}
              readOnly
            />
            <InputField
              label="Vencimento"
              value={formatDate(
                new Date(task?.due_date as string),
                'dd/MM/yyyy',
              )}
              readOnly
            />
          </Box>
        </Container>

        <Recipients taskId={taskId} />
      </Box>

      <Box style={{ flex: 1 }}>
        <Container>
          <Flex py="4" gap="4">
            <Heading size="4" color="gray">
              E-mail
            </Heading>
          </Flex>

          <Separator my="3" size="4" />

          <Box>
            <Mail taskId={taskId} />
          </Box>
        </Container>
      </Box>
    </Flex>
  );
};

export default TaskDetail;
