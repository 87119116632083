import { useCallback, useMemo, useState } from 'react';

import { ExternalLinkIcon } from '@radix-ui/react-icons';
import {
  Box,
  Button,
  DataList,
  Flex,
  HoverCard,
  Switch,
  Text,
} from '@radix-ui/themes';
import { NavLink } from 'react-router-dom';

import StatusColor from 'modules/tasks/components/StatusColor';

import { ROUTES } from 'config/routes';

import { useUpdateTask } from 'services/tasks';
import { TaskStatus } from 'services/tasks/types';

import { formatDate } from 'utils/date';

import { TaskPreviewProps } from './types';

const TaskPreview = ({ task, company, reference }: TaskPreviewProps) => {
  const { mutate, data, isPending } = useUpdateTask();

  const [status, setStatus] = useState<TaskStatus>(task.status);

  const updateTask = useCallback(
    (checked: boolean) => {
      mutate(
        {
          id: task.id,
          ignored_at: checked
            ? formatDate(new Date(), 'yyyy-MM-dd HH:mm:ssXXXXX')
            : null,
        },
        {
          onSuccess(data) {
            setStatus(data.status);
          },
        },
      );
    },
    [mutate, task.id],
  );

  const ignoredAt = useMemo(() => {
    return data?.ignored_at ?? task.ignored_at;
  }, [data?.ignored_at, task.ignored_at]);

  // const handleRedirectDetail = useCallback(() => {
  //   navigate(`${task.id}`, {
  //     preventScrollReset: true,
  //   });
  // }, [navigate, task.id]);

  return (
    <HoverCard.Root>
      <HoverCard.Trigger>
        <NavLink
          to={`/p/${ROUTES.PRIVATE.TASKS}/${task.id}`}
          target="_blank"
          style={{ textDecoration: 'none' }}
        >
          <Box>
            <StatusColor
              status={status}
              text={task.communication_purpose.acronym}
              isCreated={Boolean(task.mail_id && !task.done_at)}
            />
          </Box>
        </NavLink>
      </HoverCard.Trigger>

      <HoverCard.Content>
        <Text weight="bold">{task.communication_purpose.name}</Text>

        <DataList.Root mt="4">
          <DataList.Item align="center">
            <DataList.Label minWidth="140px">Empresa</DataList.Label>
            <DataList.Value>{company}</DataList.Value>
          </DataList.Item>

          <DataList.Item align="center">
            <DataList.Label minWidth="140px">Data de envio</DataList.Label>
            <DataList.Value>
              {task.done_at
                ? `${formatDate(new Date(task.done_at), "dd/MM/yyyy 'às' HH:mm")}`
                : 'Não informado'}
            </DataList.Value>
          </DataList.Item>

          <DataList.Item align="center">
            <DataList.Label minWidth="140px">Referência</DataList.Label>
            <DataList.Value>
              {task.reference_date
                ? `${reference}/${formatDate(
                    new Date(task.reference_date),
                    'yyyy',
                  )}`
                : 'Não informado'}
            </DataList.Value>
          </DataList.Item>

          <DataList.Item align="center">
            <DataList.Label minWidth="140px">Vencimento</DataList.Label>
            <DataList.Value>
              {task.due_date
                ? formatDate(new Date(task.due_date), 'dd/MM/yyyy')
                : 'Não informado'}
            </DataList.Value>
          </DataList.Item>

          <DataList.Item align="center">
            <DataList.Label minWidth="140px">Status</DataList.Label>
            <DataList.Value>
              <Flex gap="2">
                <StatusColor status={status} text={status} isCreated={false} />

                {ignoredAt && (
                  <Text size="1" color="gray">
                    {`em ${formatDate(new Date(ignoredAt), "dd/MM/yyyy 'às' HH:mm")}`}
                  </Text>
                )}
              </Flex>
            </DataList.Value>
          </DataList.Item>

          <DataList.Item align="center">
            <DataList.Label minWidth="140px">
              <Flex gap="2">
                <Switch
                  checked={Boolean(ignoredAt)}
                  onCheckedChange={updateTask}
                  disabled={isPending}
                />
                <Text>Ignorar</Text>
              </Flex>
            </DataList.Label>
            <DataList.Value>
              <NavLink
                to={`/p/${ROUTES.PRIVATE.TASKS}/${task.id}`}
                target="_blank"
                style={{ textDecoration: 'none' }}
              >
                <Button variant="soft">
                  <ExternalLinkIcon width={16} height={16} /> Ver tarefa
                </Button>
              </NavLink>
            </DataList.Value>
          </DataList.Item>
        </DataList.Root>
      </HoverCard.Content>
    </HoverCard.Root>
  );
};

export default TaskPreview;
