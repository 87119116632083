/**
 * Get a locale number
 */
export const formatNumber = (
  value: number,
  options?: Intl.NumberFormatOptions,
): string => {
  return new Intl.NumberFormat('pt-BR', {
    ...options,
  }).format(value);
};
