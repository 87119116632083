import {
  InfiniteData,
  UseInfiniteQueryOptions,
  UseMutationOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import { PAGE_SIZE } from 'config/constants';

import { ApiError, ApiResponse } from 'entities/Response';

import { baseApi } from 'libs/axios';

import parseData from 'utils/parseData';

import {
  createUseCommunicationPurposes,
  createUseTemplateMail,
  createUseTemplateMails,
} from './keys';
import {
  CommunicationPurposesParams,
  CommunicationPurposesResponse,
  MailData,
  MailResponse,
  MailSendData,
  TemplateMailData,
  TemplateMailResponse,
  TemplateMailsParams,
  TemplateMailsResponse,
} from './types';

export const useTemplatesMails = (
  params?: TemplateMailsParams,
  options?: UseQueryOptions<
    TemplateMailsResponse,
    ApiError,
    TemplateMailsResponse
  >,
) => {
  return useQuery({
    queryFn: () => baseApi.get('templates', { params }).then(parseData),
    queryKey: createUseTemplateMails(params),
    ...options,
  });
};

export const useTemplateMail = (
  id: string,
  options?: Partial<
    UseQueryOptions<TemplateMailResponse, ApiError, TemplateMailResponse>
  >,
) => {
  return useQuery({
    queryKey: createUseTemplateMail(id),
    queryFn: () => baseApi.get(`templates/${id}`).then(parseData),
    ...options,
  });
};

export const useUpdateTemplateMail = (
  options?: UseMutationOptions<
    TemplateMailResponse,
    ApiError,
    TemplateMailData & { id: string }
  >,
) => {
  return useMutation<
    TemplateMailResponse,
    ApiError,
    TemplateMailData & { id: string }
  >({
    mutationFn: (data) =>
      baseApi.patch(`templates/${data.id}`, data).then(parseData),
    ...options,
  });
};

export const useCreateTemplateMail = (
  options?: UseMutationOptions<
    TemplateMailResponse,
    ApiError,
    TemplateMailData
  >,
) => {
  return useMutation<TemplateMailResponse, ApiError, TemplateMailData>({
    mutationFn: (data) => baseApi.post(`templates`, data).then(parseData),
    ...options,
  });
};

export const useRemoveTemplateMail = (
  options?: UseMutationOptions<undefined, ApiError, { id: string }>,
) => {
  return useMutation<undefined, ApiError, { id: string }>({
    mutationFn: (data) =>
      baseApi.delete(`templates/${data.id}`).then(parseData),
    ...options,
  });
};

export const useUpdateMail = (
  options?: UseMutationOptions<MailResponse, ApiError, MailData>,
) => {
  return useMutation<MailResponse, ApiError, MailData>({
    mutationFn: (data) =>
      baseApi
        .patch<ApiResponse<MailResponse>>(`mails/${data.id}`, data)
        .then(parseData),
    ...options,
  });
};

export const useCommunicationPurposes = (
  params?: CommunicationPurposesParams,
  options?: Partial<
    UseInfiniteQueryOptions<
      CommunicationPurposesResponse,
      ApiError,
      InfiniteData<CommunicationPurposesResponse>
    >
  >,
) => {
  return useInfiniteQuery({
    queryKey: createUseCommunicationPurposes(params),
    queryFn: ({ pageParam = params?.page }) =>
      baseApi
        .get(`communication-purposes`, {
          params: {
            page: pageParam,
            ...params,
          },
        })
        .then((r) => r.data),
    initialPageParam: 1,
    ...options,
    getNextPageParam: ({ num_pages }) => {
      return Math.ceil(num_pages / PAGE_SIZE) <= (params?.page ?? 0)
        ? (params?.page ?? 0) + 1
        : null;
    },
  });
};

export const useSendMail = (
  options?: UseMutationOptions<MailResponse, ApiError, MailSendData>,
) => {
  return useMutation<MailResponse, ApiError, MailSendData>({
    mutationFn: (data) =>
      baseApi.post(`mails/${data.id}/send`, {
        sent_by_id: data.user_id,
      }),
    ...options,
  });
};
