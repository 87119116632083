import { useCallback, useEffect, useRef, useState } from 'react';

import { Box, Button, Flex } from '@radix-ui/themes';
import { Toast } from 'primereact/toast';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import IconLoading from 'components/IconLoading';
import InputField from 'components/InputField';

import { useCompany, useUpdateCompany } from 'services/companies';
import { usePostalCode } from 'services/utils';

import { FormData } from './types';

const AddressForm = () => {
  const [postalCode, setPostalCode] = useState<string>();
  const { id } = useParams();

  const {
    register,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    reValidateMode: 'onChange',
  });
  const toastBC = useRef<Toast>(null);
  const { data } = useCompany(id);
  const { mutate, isPending } = useUpdateCompany();

  const { data: address, isLoading } = usePostalCode(postalCode ?? '', {
    enabled: postalCode?.length === 9,
  });

  const setData = useCallback(
    (data: FormData) => {
      setValue('additional', data.additional);
      setValue('city', data.city);
      setValue('postal_code', data.postal_code);
      setValue('district', data.district);
      setValue('state', data.state);
      setValue('street', data.street);
      setValue('number', data.number);
    },
    [setValue],
  );

  useEffect(() => {
    if (data?.address) {
      setData(data.address);
    }
  }, [data, setData]);

  useEffect(() => {
    if (address) {
      clearErrors('postal_code');
      setData({
        city: address.localidade,
        district: address.bairro,
        state: address.uf,
        street: address.logradouro,
        number: '',
        additional: '',
        postal_code: address.cep,
      });
    }
    if (address?.erro) {
      setError('postal_code', {
        message: 'CEP não encontrado',
      });
    }
  }, [address, clearErrors, setData, setError]);

  const onSubmit = useCallback(
    (formData: FormData) => {
      mutate(
        {
          id: data?.id,
          address: formData,
        },
        {
          onSuccess() {
            toastBC.current?.show({
              severity: 'success',
              summary: 'Empresa',
              detail: 'Endereço atualizado com sucesso.',
              life: 5000,
            });
          },
          onError() {
            toastBC.current?.show({
              severity: 'error',
              summary: 'Empresa',
              detail: 'Erro ao atualizar cadastro.',
              life: 5000,
            });
          },
        },
      );
    },
    [data?.id, mutate],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputField
        label="CEP"
        error={errors.postal_code?.message}
        mask="ddddd-ddd"
        {...register('postal_code', {
          required: 'CEP é obrigatório',
          onChange(event: React.ChangeEvent<HTMLInputElement>) {
            setPostalCode(event.target.value);
            return event;
          },
        })}
        endAdornment={
          isLoading ? <IconLoading width="24" height="24" /> : undefined
        }
      />
      <InputField
        label="Endereço"
        error={errors.street?.message}
        {...register('street', { required: 'Endereço é obrigatório' })}
      />
      <InputField
        label="Bairro"
        error={errors.district?.message}
        {...register('district')}
      />

      <Flex gap="4">
        <InputField
          label="Número"
          error={errors.number?.message}
          {...register('number', { required: 'Número é obrigatório' })}
        />

        <Flex style={{ flex: 1 }}>
          <Box width="100%">
            <InputField
              label="Complemento"
              error={errors.additional?.message}
              {...register('additional')}
            />
          </Box>
        </Flex>
      </Flex>

      <Flex gap="4">
        <InputField
          label="Estado"
          error={errors.state?.message}
          {...register('state', {
            required: 'Estado é obrigatório',
          })}
        />

        <Flex style={{ flex: 1 }}>
          <Box width="100%">
            <InputField
              label="Cidade"
              error={errors.city?.message}
              {...register('city', {
                required: 'Cidade é obrigatório',
              })}
            />
          </Box>
        </Flex>
      </Flex>

      <Flex gap="4" justify="end">
        <Button size="3" type="submit" disabled={isPending}>
          {isPending && <IconLoading />} Salvar
        </Button>
      </Flex>
      <Toast ref={toastBC} />
    </form>
  );
};

export default AddressForm;
