import { useCallback, useRef } from 'react';

import { CrossCircledIcon } from '@radix-ui/react-icons';
import { Box, Flex, Skeleton, Text } from '@radix-ui/themes';

import InputField from 'components/InputField';

import { InputSearchProps } from './types';

import './styles.css';

const InputSearch = ({
  onChange,
  total,
  placeholder,
  disabled,
  isLoading = false,
}: InputSearchProps) => {
  const htmlInputRef = useRef<HTMLInputElement>(null);

  const handleResetSearch = useCallback(() => {
    if (htmlInputRef.current) {
      htmlInputRef.current.value = '';
    }

    onChange('');
  }, [onChange]);

  return (
    <Flex align="center" gap="4">
      <Box className="input-search">
        <InputField
          ref={htmlInputRef}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
          disabled={disabled}
          endAdornment={
            htmlInputRef.current?.value ? (
              <CrossCircledIcon
                width={18}
                height={18}
                cursor="pointer"
                onClick={handleResetSearch}
              />
            ) : undefined
          }
        />
      </Box>

      <Box>
        <Text>
          <Skeleton loading={isLoading}>Total: {total ?? 0}</Skeleton>
        </Text>
      </Box>
    </Flex>
  );
};

export default InputSearch;
