import type { QueryKey } from '@tanstack/react-query';

import { CompaniesParams } from './types';

export const createUseCompanies = (params: CompaniesParams): QueryKey => [
  `useCompanies`,
  params,
];

export const createUseCompany = (companyId: string): QueryKey => [
  `useCompany`,
  companyId,
];

export const createUseCompanyContacts = (companyId: string): QueryKey => [
  `useCompanyContacts`,
  companyId,
];

export const createUseCompanyContact = (
  companyId: string,
  contactId: number,
): QueryKey => [`useCompanyContacts`, companyId, contactId];
