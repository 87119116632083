import { InfoCircledIcon } from '@radix-ui/react-icons';
import { Callout, Strong } from '@radix-ui/themes';

const AlertMail = () => {
  return (
    <Callout.Root color="orange" mb="4">
      <Callout.Icon>
        <InfoCircledIcon />
      </Callout.Icon>
      <Callout.Text>
        Atenção! Este e-mail ainda não foi criado, está preenchido de forma
        automática, para habilitar o envio, complete com as informações
        necessárias e clique em <Strong>salvar</Strong>.
      </Callout.Text>
    </Callout.Root>
  );
};

export default AlertMail;
