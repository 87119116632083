import { Text } from '@radix-ui/themes';

import { InputErrorProps } from './types';

const InputError = ({ error }: InputErrorProps) => {
  return (
    <Text color="red" size="1">
      {error}
    </Text>
  );
};

export default InputError;
