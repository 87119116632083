import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { PAGE_SIZE } from 'config/constants';

import type { ApiError } from 'entities/Response';

import { baseApi } from 'libs/axios';

import { createUseManagers } from './keys';
import type { ManagersParams, ManagersResponse } from './types';

export const useManagers = (
  params?: ManagersParams,
  options?: UseQueryOptions<ManagersResponse, ApiError, ManagersResponse>,
) => {
  return useQuery({
    queryKey: createUseManagers(params),
    queryFn: ({ pageParam = params?.page ?? 1 }) =>
      baseApi
        .get(`staffs`, {
          params: {
            page: pageParam,
            page_size: PAGE_SIZE,
            ...params,
          },
        })
        .then((r) => r.data),
    ...options,
  });
};
