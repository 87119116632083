import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import type { ApiError, ApiResponse } from 'entities/Response';

import { baseCep } from 'libs/axios';

import parseData from 'utils/parseData';

import { createUsePostalCode } from './keys';
import type { CepResponse } from './types';

export const usePostalCode = (
  postalCode: string,
  options?: Partial<UseQueryOptions<CepResponse, ApiError, CepResponse>>,
) => {
  return useQuery({
    queryKey: createUsePostalCode(postalCode),
    queryFn: () =>
      baseCep
        .get<ApiResponse<CepResponse>>(`ws/${postalCode}/json`)
        .then(parseData),
    staleTime: Infinity,
    ...options,
  });
};
