import { useMemo } from 'react';

import {
  EnvelopeClosedIcon,
  LightningBoltIcon,
  MixIcon,
  RocketIcon,
  TokensIcon,
  PersonIcon,
} from '@radix-ui/react-icons';
import { Box, Flex } from '@radix-ui/themes';

import { ROUTES } from 'config/routes';

import MenuItem from 'layouts/Default/MenuItem';

const SIZE_ICON = '24';

const Menu = () => {
  const items = useMemo(() => {
    return [
      {
        name: 'Dashboard',
        icon: <TokensIcon width={SIZE_ICON} height={SIZE_ICON} />,
        path: ROUTES.PRIVATE.DASHBOARD,
      },
      {
        name: 'Clientes',
        icon: <RocketIcon width={SIZE_ICON} height={SIZE_ICON} />,
        path: ROUTES.PRIVATE.COMPANIES,
      },
      {
        name: 'Tarefas',
        icon: <MixIcon width={SIZE_ICON} height={SIZE_ICON} />,
        path: ROUTES.PRIVATE.TASKS,
      },
      {
        name: 'Mailing',
        icon: <EnvelopeClosedIcon width={SIZE_ICON} height={SIZE_ICON} />,
        path: ROUTES.PRIVATE.MAILING,
      },
      {
        name: 'Contatos',
        icon: <PersonIcon width={SIZE_ICON} height={SIZE_ICON} />,
        path: ROUTES.PRIVATE.CONTACTS,
      },
    ];
  }, []);

  return (
    <Flex align="center" direction="column" justify="between">
      <Box py="6">
        <LightningBoltIcon width="44" height="44" color="#FFDC00" />
      </Box>

      <Flex align="center" style={{ flex: 1 }}>
        <Flex gap="4" align="center" direction="column">
          {items.map((item) => (
            <MenuItem
              key={item.path}
              icon={item.icon}
              name={item.name}
              path={item.path}
            />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Menu;
