import { Box, Text } from '@radix-ui/themes';

import { InputLabelProps } from './types';

const InputLabel = ({ text }: InputLabelProps) => {
  return (
    <Box mb="1">
      <Text as="label">{text}</Text>
    </Box>
  );
};

export default InputLabel;
