export enum TaskStatus {
  Pendente = 'Pendente',
  Urgente = 'Urgente',
  Atrasado = 'Atrasado',
  Concluído = 'Concluído',
  Ignorado = 'Ignorado',
  Arquivado = 'Arquivado',
}

export type Task = {
  comments: string;
  communication_purpose: {
    acronym: string;
    due_delta: number;
    id: number;
    is_archived: boolean;
    is_private: boolean;
    late_delta: number;
    name: string;
    recurrence: string;
    template_id: string;
    urgency_delta: number;
  };
  company_id: string;
  cycle_date: string;
  done_at: string;
  due_date: string;
  id: number;
  ignored_at: string | null;
  mail_id: string;
  reference_date: string;
  status: TaskStatus;
};

export interface TasksParams {
  reference_date__gte: string;
  reference_date__lte: string;
  company_id: number;
  page_size: number;
  order_by: string;
}

export interface TasksResponse {
  count: number;
  num_pages: number;
  results: Task[];
}

export type TaskResponse = Task;

export type MailData = FormData;
