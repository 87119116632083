import { QueryKey } from '@tanstack/react-query';

import { CommunicationPurposesParams, TemplateMailsParams } from './types';

export const createUseCommunicationPurposes = (
  params?: CommunicationPurposesParams,
): QueryKey => [`useCommunicationPurposes`, params];

export const createUseTemplateMails = (
  params?: TemplateMailsParams,
): QueryKey => [`useTemplateMails`, params];

export const createUseTemplateMail = (id: string): QueryKey => [
  `useTemplateMail`,
  id,
];
