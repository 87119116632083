import { StrictMode } from 'react';

import { Theme } from '@radix-ui/themes';
import { createRoot } from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import CompanieDetail from 'modules/companies/pages/CompanieDetail';
import CompaniesList from 'modules/companies/pages/CompaniesList';
import EmployeeDetail from 'modules/config/pages/EmployeeDetail';
import StaffsList from 'modules/config/pages/StaffsList';
import TemplateMailing from 'modules/config/pages/TemplateMailing';
import TemplateMailingForm from 'modules/config/pages/TemplateMailingForm';
import ContactDetail from 'modules/contacts/pages/ContactDetail';
import ContactsList from 'modules/contacts/pages/ContactsList';
import MailingBoard from 'modules/mailing/pages/MailingBoard';
import TaskDetail from 'modules/tasks/pages/TaskDetail';
import TasksBoard from 'modules/tasks/pages/TasksBoard';
import ConfirmPassword from 'pages/ConfirmPassword';
import Dashboard from 'pages/Dashboard';
import ForgotPassword from 'pages/ForgotPassword';
import SignIn from 'pages/SignIn';

import { ROUTES } from 'config/routes';

import Auth from 'layouts/Auth';
import Default from 'layouts/Default';

import { ReactQuery } from 'libs/react-query';

import reportWebVitals from './reportWebVitals';

import '@radix-ui/themes/styles.css';

import './index.css';

const root = createRoot(document.getElementById('root') as HTMLElement);

const router = createBrowserRouter([
  {
    path: '/',
    element: <Auth />,
    children: [
      {
        path: '',
        element: <SignIn />,
      },
      {
        path: ROUTES.PUBLIC.FORGOT_PASSWORD,
        element: <ForgotPassword />,
      },
      {
        path: ROUTES.PUBLIC.CONFIRM_PASSWORD,
        element: <ConfirmPassword />,
      },
    ],
  },
  {
    path: 'p',
    element: <Default />,
    children: [
      {
        path: ROUTES.PRIVATE.DASHBOARD,
        element: <Dashboard />,
      },
      {
        path: ROUTES.PRIVATE.COMPANIES,
        element: <CompaniesList />,
      },
      {
        path: ROUTES.PRIVATE.COMPANIE,
        element: <CompanieDetail />,
      },
      {
        path: ROUTES.PRIVATE.COMPANIES_CREATE,
        element: <CompanieDetail />,
      },
      {
        path: ROUTES.PRIVATE.TASKS,
        element: <TasksBoard />,
      },
      {
        path: ROUTES.PRIVATE.TASK_DETAIL,
        element: <TaskDetail />,
      },
      {
        path: ROUTES.PRIVATE.MAILING,
        element: <MailingBoard />,
      },
      {
        path: ROUTES.PRIVATE.CONTACTS,
        element: <ContactsList />,
      },
      {
        path: ROUTES.PRIVATE.CONTACT_DETAIL,
        element: <ContactDetail />,
      },

      // Staffs
      {
        path: ROUTES.PRIVATE.STAFFS,
        element: <StaffsList />,
      },
      {
        path: ROUTES.PRIVATE.STAFFS_CREATE,
        element: <EmployeeDetail />,
      },
      {
        path: ROUTES.PRIVATE.EMPLOYEE_DETAIL,
        element: <EmployeeDetail />,
      },

      // mails
      {
        path: ROUTES.PRIVATE.TEMPLATES_MAILS,
        element: <TemplateMailing />,
      },
      {
        path: ROUTES.PRIVATE.TEMPLATES_MAILS_CREATE,
        element: <TemplateMailingForm />,
      },
      {
        path: ROUTES.PRIVATE.TEMPLATES_MAILS_FORM,
        element: <TemplateMailingForm />,
      },
    ],
  },
]);

root.render(
  <StrictMode>
    <Theme
      // accentColor="blue"
      grayColor="sage"
      // radius="medium"
      scaling="100%"
      panelBackground="solid"
      appearance="light"
    >
      <ReactQuery>
        {/* <Default> */}
        <RouterProvider router={router} fallbackElement={<h1>loading...</h1>} />
        {/* </Default> */}
      </ReactQuery>
    </Theme>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
