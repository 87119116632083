import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  ExclamationTriangleIcon,
  InfoCircledIcon,
  PaperPlaneIcon,
  PlusIcon,
} from '@radix-ui/react-icons';
import {
  AlertDialog,
  Badge,
  Box,
  Button,
  Callout,
  Flex,
  Strong,
} from '@radix-ui/themes';
import { useQueryClient } from '@tanstack/react-query';
import { Editor } from 'primereact/editor';
import { Toast } from 'primereact/toast';
import { Controller, useForm } from 'react-hook-form';

import AlertMail from 'modules/tasks/components/AlertMail';
import AttachmentsMail from 'modules/tasks/components/AttachmentsMail';

import IconLoading from 'components/IconLoading';
import InputError from 'components/InputError';
import InputField from 'components/InputField';
import InputFile from 'components/InputFile';
import InputLabel from 'components/InputLabel';
import RecipientsDialog from 'components/RecipientsDialog';

import { useUpdateAttachments } from 'services/attachments';
import { useCompany } from 'services/companies';
import { useSendMail, useUpdateMail } from 'services/mails';
import { useTaskCreateMail, useTaskDetail, useTaskMail } from 'services/tasks';
import { createUseTask } from 'services/tasks/keys';
import { useCurrentUser, useSystemUser } from 'services/user';

import { formatDate } from 'utils/date';

import { FormProps, MailProps } from './types';

import 'primereact/resources/themes/md-light-indigo/theme.css';
import './styles.css';

const Mail = ({ taskId }: MailProps) => {
  const { data: user } = useCurrentUser();
  const { data: task } = useTaskDetail(taskId);
  const { data: mail, refetch: refetchMail } = useTaskMail(taskId);
  const { data: company } = useCompany(task?.company_id as string, {
    enabled: !!task?.company_id,
  });

  const { data: currentUser } = useCurrentUser();
  const { mutate: createMail, isPending: isPendingCreateMail } =
    useTaskCreateMail(taskId);
  const { mutate: updateMail, isPending: isPendingUpdateMail } =
    useUpdateMail();
  const { mutate: updateAttachments, isPending: isPendingUpdateAttachments } =
    useUpdateAttachments();

  const { mutate: senMail } = useSendMail();

  const { data: systemUser, refetch: refetchSystemUser } = useSystemUser(
    mail?.sent_by_id as string,
    {
      enabled: Boolean(mail?.sent_by_id),
    },
  );

  const [openUpdateAlert, setOpenUpdateAlert] = useState<boolean>(false);
  const [openSendAlert, setOpenSendAlert] = useState<boolean>(false);

  const toastBC = useRef<Toast>(null);

  const queryClient = useQueryClient();

  const isLoading = useMemo(() => {
    return (
      isPendingCreateMail || isPendingUpdateMail || isPendingUpdateAttachments
    );
  }, [isPendingCreateMail, isPendingUpdateAttachments, isPendingUpdateMail]);

  const referenceDate = useMemo(() => {
    return formatDate(
      new Date(task?.reference_date.replace('-', '/') ?? 0),
      'MMM/yyyy',
    ).toUpperCase();
  }, [task?.reference_date]);

  const emailSubject = useMemo(() => {
    return `${task?.communication_purpose.name} - ${referenceDate} - ${company?.trading_name}`;
  }, [company?.trading_name, referenceDate, task?.communication_purpose.name]);

  const emailTitle = useMemo(() => {
    return `${task?.communication_purpose.name} - ${referenceDate}`;
  }, [referenceDate, task?.communication_purpose.name]);

  const {
    control,
    register,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<FormProps>({
    defaultValues: {
      recipients: [],
    },
  });

  useEffect(() => {
    setValue('recipients', mail?.recipients ?? []);
    setValue('variables.email_body', mail?.variables.email_body as string);
    setValue(
      'variables.email_subject',
      mail?.variables.email_subject || emailSubject,
    );
    setValue(
      'variables.email_title',
      mail?.variables.email_title || emailTitle,
    );
  }, [mail, setValue, emailTitle, emailSubject]);

  const recipients = watch('recipients');

  const onSubmit = useCallback(
    (data: FormProps) => {
      if (mail?.id) {
        if (mail.sent_at) {
          updateMail(
            {
              id: mail.id,
              variables: data.variables,
              recipients: data.recipients,
            },
            {
              onSuccess() {
                refetchMail();
                toastBC.current?.show({
                  severity: 'success',
                  summary: 'E-mail',
                  detail: 'E-mail atualizado com sucesso.',
                  life: 5000,
                });
              },
            },
          );
        } else {
          updateMail(
            {
              id: mail.id,
              variables: data.variables,
              recipients: data.recipients,
            },
            {
              onSuccess() {
                refetchMail();
                toastBC.current?.show({
                  severity: 'success',
                  summary: 'E-mail',
                  detail: 'E-mail atualizado com sucesso.',
                  life: 5000,
                });
              },
            },
          );
        }

        if (data.attachments?.length) {
          setOpenUpdateAlert(true);
        }
      } else {
        const formData = new FormData();
        const file = data.attachments?.[0];

        formData.append('attachments', file as File);
        formData.append('template_id', mail?.template_id as string);
        formData.append('recipients', JSON.stringify(data.recipients));
        formData.append('variables', JSON.stringify(data.variables));
        formData.append('sent_by_id', JSON.stringify(user?.me.id));
        formData.append('bcc', 'false');
        formData.append('to_do_id', mail?.to_do_id as string);

        createMail(formData, {
          onSuccess() {
            toastBC.current?.show({
              severity: 'success',
              summary: 'E-mail',
              detail: 'E-mail criado com sucesso.',
              life: 5000,
            });
            refetchMail();
            setValue('attachments', undefined);
          },
        });
      }
    },

    [createMail, mail, refetchMail, setValue, updateMail, user?.me.id],
  );

  const handleUpdateAttachments = useCallback(() => {
    const formData = new FormData();
    const file = getValues('attachments')?.[0];

    formData.append('content', file as File);
    updateAttachments(
      {
        id: mail?.attachments?.[0].id as number,
        formData,
      },
      {
        onSuccess() {
          refetchMail();
          setValue('attachments', undefined);

          toastBC.current?.show({
            severity: 'success',
            summary: 'Anexo',
            detail: 'Anexo atualizado com sucesso.',
            life: 5000,
          });
        },
      },
    );
  }, [getValues, mail?.attachments, refetchMail, setValue, updateAttachments]);

  const handeOnSendMail = useCallback(() => {
    senMail(
      {
        id: mail?.id as number,
        user_id: currentUser?.me.id as number,
      },
      {
        onSuccess() {
          refetchMail();
          setValue('attachments', undefined);

          queryClient.refetchQueries({
            queryKey: createUseTask(taskId),
          });

          toastBC.current?.show({
            severity: 'success',
            summary: 'Anexo',
            detail: 'E-mail enviado com sucesso.',
            life: 5000,
          });

          refetchSystemUser();
        },
      },
    );
  }, [
    currentUser?.me.id,
    mail?.id,
    queryClient,
    refetchMail,
    refetchSystemUser,
    senMail,
    setValue,
    taskId,
  ]);

  return (
    <Box>
      {mail && !mail?.id && <AlertMail />}

      {mail?.sent_at && (
        <Callout.Root mb="4" color="green">
          <Callout.Icon>
            <InfoCircledIcon />
          </Callout.Icon>
          <Callout.Text>
            Este e-mail foi enviado no dia{' '}
            {formatDate(new Date(mail.sent_at), "dd/MM/yyyy 'às' HH:mm")} por{' '}
            {systemUser?.name}.
          </Callout.Text>
        </Callout.Root>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <InputField label="Assunto" {...register('variables.email_subject')} />

        <Box mb="5">
          <InputLabel text="Destinátários" />

          {mail?.recipients && mail.recipients.length > 0 && (
            <RecipientsDialog
              defaultValue={mail.to_do_recipients}
              value={recipients}
              onChange={(recipients) => setValue('recipients', recipients)}
              trigger={
                <Box>
                  <Flex
                    className="recipients"
                    p="2"
                    gap="2"
                    style={{
                      borderColor:
                        recipients.length === 0
                          ? 'var(--red-11)'
                          : 'var(--gray-7)',
                    }}
                  >
                    {recipients.slice(0, 2).map((contact) => (
                      <Box key={contact.email}>
                        <Badge radius="full">{contact.email}</Badge>
                      </Box>
                    ))}

                    <Box>
                      {recipients.length > 2 && (
                        <Badge radius="full">+ {recipients.length - 2}</Badge>
                      )}
                    </Box>
                  </Flex>

                  {recipients.length === 0 && (
                    <InputError error="Nenhum contato selecionado" />
                  )}
                </Box>
              }
            />
          )}
        </Box>

        <InputField
          label="Título do e-mail"
          error={errors.variables?.email_title?.message}
          disabled={Boolean(task?.done_at)}
          {...register('variables.email_title', {
            required: 'Título é obrigatório',
          })}
        />

        <Box mb="4">
          <Controller
            name="variables.email_body"
            control={control}
            render={({ field }) => (
              <Editor
                id={field.name}
                name={field.name}
                value={field.value}
                onTextChange={(e) => field.onChange(e.htmlValue)}
                style={{ height: '320px' }}
              />
            )}
          />

          {errors.variables?.email_body?.message && (
            <InputError error={errors.variables.email_body.message} />
          )}
        </Box>

        <InputFile
          error={errors.attachments?.message}
          {...register('attachments', {
            required: {
              value: !((mail?.attachments.length ?? 0) > 0),
              message: 'Anexo é obrigatório',
            },
          })}
        />

        {mail?.attachments && mail.attachments.length > 0 && (
          <AttachmentsMail
            filename={mail.attachments[0].filename}
            id={mail.attachments[0].id}
            url={mail.attachments[0].url}
          />
        )}

        <Flex gap="4" align="center" justify="between">
          <Box>
            <Button
              color="green"
              type="button"
              disabled={!mail?.id}
              onClick={() => setOpenSendAlert((prevState) => !prevState)}
            >
              <PaperPlaneIcon />
              Enviar
            </Button>
          </Box>
          <Box>
            <Button type="submit" disabled={isLoading}>
              {isLoading ? <IconLoading /> : <PlusIcon />}
              Salvar
            </Button>
          </Box>
        </Flex>
      </form>

      <AlertDialog.Root
        open={openUpdateAlert}
        onOpenChange={setOpenUpdateAlert}
      >
        <AlertDialog.Content style={{ maxWidth: 450 }}>
          <AlertDialog.Title>Atenção</AlertDialog.Title>
          <AlertDialog.Description size="2">
            Você está alterando este anexo, o mesmo será atualizado para os
            contatos que receberam este e-mail, tem certeza dessa alteração?
          </AlertDialog.Description>

          <Flex gap="3" mt="4" justify="end">
            <AlertDialog.Cancel>
              <Button variant="soft" color="gray">
                Cancelar
              </Button>
            </AlertDialog.Cancel>
            <AlertDialog.Action onClick={handleUpdateAttachments}>
              <Button variant="solid" color="green">
                Sim, quero alterar
              </Button>
            </AlertDialog.Action>
          </Flex>
        </AlertDialog.Content>
      </AlertDialog.Root>

      <AlertDialog.Root open={openSendAlert} onOpenChange={setOpenSendAlert}>
        <AlertDialog.Content style={{ maxWidth: 470 }}>
          <AlertDialog.Title>Atenção</AlertDialog.Title>
          <AlertDialog.Description size="2">
            {mail?.sent_at ? (
              <>
                <p>
                  Você está enviando esta tarefa{' '}
                  <strong>{task?.communication_purpose.name}</strong> novamente,
                  tem certeza que deseje enviar?
                </p>
              </>
            ) : (
              <>
                <p>
                  Tem certeza que deseja enviar esta tarefa?{' '}
                  <strong>{task?.communication_purpose.name}</strong>.
                </p>

                {recipients.length === 1 && (
                  <Callout.Root color="yellow" role="alert">
                    <Callout.Icon>
                      <ExclamationTriangleIcon />
                    </Callout.Icon>
                    <Callout.Text>
                      Você está enviando este e-mail apenas para o endereço
                      cadastrado <Strong>{recipients[0].email}</Strong>.
                    </Callout.Text>
                  </Callout.Root>
                )}
              </>
            )}{' '}
          </AlertDialog.Description>

          <Flex gap="3" mt="4" justify="end">
            <AlertDialog.Cancel>
              <Button variant="soft" color="gray">
                Cancelar
              </Button>
            </AlertDialog.Cancel>
            <AlertDialog.Action onClick={handeOnSendMail}>
              <Button variant="solid" color="green">
                Sim, quero enviar
              </Button>
            </AlertDialog.Action>
          </Flex>
        </AlertDialog.Content>
      </AlertDialog.Root>

      <Toast ref={toastBC} />
    </Box>
  );
};

export default Mail;
