import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';

import type { ApiError, ApiResponse } from 'entities/Response';

import { baseApi } from 'libs/axios';

import parseData from 'utils/parseData';

import { createForgotPassword } from './keys';
import type {
  CreateNewPasswordData,
  ForgotPasswordResponse,
  SignInData,
  SignInResponse,
} from './types';

export const useSignIn = (
  options?: UseMutationOptions<SignInResponse, ApiError, SignInData>,
) => {
  return useMutation<SignInResponse, ApiError, SignInData>({
    mutationFn: (data) =>
      baseApi.post<ApiResponse<SignInResponse>>(`auth`, data).then(parseData),
    ...options,
  });
};

// ts-prune-ignore-next
export const useForgotPassword = (
  email: string,
  options?: UseQueryOptions<ForgotPasswordResponse, ApiError>,
) => {
  return useQuery({
    queryKey: createForgotPassword(email),
    queryFn: () =>
      baseApi
        .get<ForgotPasswordResponse>(`auth/reset/${email}`)
        .then(parseData),
    staleTime: Infinity,
    ...options,
  });
};

export const useCreateNewPassword = (
  options?: UseMutationOptions<SignInResponse, ApiError, CreateNewPasswordData>,
) => {
  return useMutation<SignInResponse, ApiError, CreateNewPasswordData>({
    mutationFn: (data) =>
      baseApi
        .post<ApiResponse<SignInResponse>>(`auth/reset/${data.token}`, {
          password: data.password,
        })
        .then(parseData),
    ...options,
  });
};
