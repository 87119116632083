import { useCallback, useEffect, useRef } from 'react';

import { TrashIcon } from '@radix-ui/react-icons';
import {
  AlertDialog,
  Box,
  Button,
  Flex,
  IconButton,
  Spinner,
  Strong,
  Tabs,
  Tooltip,
} from '@radix-ui/themes';
import { useQueryClient } from '@tanstack/react-query';
import { Toast } from 'primereact/toast';
import { useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import Container from 'components/Container';
import IconLoading from 'components/IconLoading';
import InputField from 'components/InputField';

import { ROUTES } from 'config/routes';

import useNavigate from 'hooks/useNavigate';

import {
  useCreateTemplateMail,
  useRemoveTemplateMail,
  useTemplateMail,
  useUpdateTemplateMail,
} from 'services/mails';
import { createUseTemplateMails } from 'services/mails/keys';

import { FormData } from './types';

const TemplateMailingForm = () => {
  const { navigate } = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useTemplateMail(id as string, {
    enabled: !!id,
  });

  const { mutate: updateTemplate, isPending: isPendingUpdateTemplate } =
    useUpdateTemplateMail();
  const { mutate: createTemplate, isPending: isPendingCreateTemplate } =
    useCreateTemplateMail();
  const { mutate: removeTemplate, isPending: isPendingRemoveTemplate } =
    useRemoveTemplateMail();

  const isPending = isPendingUpdateTemplate || isPendingCreateTemplate;

  const toastBC = useRef<Toast>(null);

  const queryClient = useQueryClient();

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'required_variables',
  });

  const onSubmit = useCallback(
    (form: FormData) => {
      if (data?.id) {
        updateTemplate(
          {
            id: String(data.id),
            external_id: form.external_id,
            name: form.name,
            required_variables: form.required_variables.map((i) => i.value),
          },
          {
            onSuccess() {
              toastBC.current?.show({
                severity: 'success',
                summary: 'Template Mailing',
                detail: 'Template atualizado com sucesso.',
                life: 5000,
              });
            },
          },
        );
      } else {
        createTemplate(
          {
            external_id: form.external_id,
            name: form.name,
            required_variables: form.required_variables.map((i) => i.value),
          },
          {
            onSuccess(data) {
              toastBC.current?.show({
                severity: 'success',
                summary: 'Template Mailing',
                detail: 'Template adicionado com sucesso.',
                life: 5000,
              });

              navigate(`${ROUTES.PRIVATE.TEMPLATES_MAILS}/${data.id}`);

              queryClient.refetchQueries({
                queryKey: createUseTemplateMails(),
              });
            },
          },
        );
      }
    },
    [createTemplate, data?.id, navigate, queryClient, updateTemplate],
  );

  const onRemoveTemplate = useCallback(() => {
    if (data?.id) {
      removeTemplate(
        {
          id: String(data.id),
        },
        {
          onSuccess(data) {
            toastBC.current?.show({
              severity: 'success',
              summary: 'Template Mailing',
              detail: 'Template adicionado com sucesso.',
              life: 5000,
            });

            navigate(ROUTES.PRIVATE.TEMPLATES_MAILS);
            queryClient.refetchQueries({
              queryKey: createUseTemplateMails(),
            });
          },
        },
      );
    }
  }, [data?.id, navigate, queryClient, removeTemplate]);

  useEffect(() => {
    if (data) {
      setValue('external_id', data.external_id);
      setValue('name', data.name);
      setValue(
        'required_variables',
        data.required_variables.map((i) => ({
          value: i,
        })),
      );
    }
  }, [data, setValue]);

  return (
    <Container>
      {isLoading ? (
        <Flex align="center" justify="center" style={{ height: 300 }}>
          <IconLoading width="48" height="48" />
        </Flex>
      ) : (
        <Tabs.Root defaultValue="account">
          <Tabs.List>
            <Tabs.Trigger value="account">Dados principais</Tabs.Trigger>
          </Tabs.List>

          <Box style={{ maxWidth: 800 }} pt="6">
            <Tabs.Content value="account">
              <form onSubmit={handleSubmit(onSubmit)}>
                <InputField
                  label="ID send grid"
                  error={errors.external_id?.message}
                  {...register('external_id', {
                    required: 'Identificador é obrigatório',
                  })}
                />

                <InputField
                  label="Nome"
                  error={errors.external_id?.message}
                  {...register('name', {
                    required: 'Nome é obrigatório',
                  })}
                />

                {fields.map((field, index) => (
                  <Flex key={field.id} gap="4" align="center">
                    <Flex style={{ flex: 1 }}>
                      <InputField
                        label={`Nome da variável: ${index + 1}`}
                        key={field.id}
                        error={
                          errors.required_variables?.[index]?.value?.message
                        }
                        {...register(`required_variables.${index}.value`, {
                          required: 'Campo obrigatório',
                        })}
                      />
                    </Flex>

                    <Flex>
                      <Tooltip content="Remover">
                        <IconButton size="1" variant="soft" type="button">
                          <TrashIcon
                            width={14}
                            height={14}
                            onClick={() => remove(index)}
                          />
                        </IconButton>
                      </Tooltip>
                    </Flex>
                  </Flex>
                ))}

                <Button
                  type="button"
                  onClick={() => {
                    append({ value: '' });
                  }}
                >
                  Adicionar variável
                </Button>

                <Flex gap="4" mt="4">
                  <Flex
                    style={{
                      flex: 1,
                    }}
                  >
                    {data?.id && data?.name && (
                      <AlertDialog.Root>
                        <AlertDialog.Trigger>
                          <Button color="crimson" variant="soft" type="button">
                            Excluir
                          </Button>
                        </AlertDialog.Trigger>
                        <AlertDialog.Content maxWidth="450px">
                          <AlertDialog.Title>Atenção</AlertDialog.Title>
                          <AlertDialog.Description size="2">
                            Tem certeza de deseja remover{' '}
                            <Strong>{data.name}</Strong>?
                          </AlertDialog.Description>

                          <Flex gap="3" mt="4" justify="end">
                            <AlertDialog.Cancel>
                              <Button variant="soft" color="gray">
                                Cancelar
                              </Button>
                            </AlertDialog.Cancel>
                            <AlertDialog.Action>
                              <Button
                                variant="solid"
                                color="red"
                                onClick={onRemoveTemplate}
                                loading={isPendingRemoveTemplate}
                              >
                                Excluir
                              </Button>
                            </AlertDialog.Action>
                          </Flex>
                        </AlertDialog.Content>
                      </AlertDialog.Root>
                    )}
                  </Flex>

                  <Flex justify="end">
                    <Button size="3" type="submit" disabled={isPending}>
                      {isPending && <Spinner />} Salvar
                    </Button>
                  </Flex>
                </Flex>
              </form>
            </Tabs.Content>
          </Box>
        </Tabs.Root>
      )}

      <Toast ref={toastBC} />
    </Container>
  );
};

export default TemplateMailingForm;
