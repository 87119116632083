/**
 * Remove non-numeric characters from a string
 * and if pattern equals currency, divide by 100
 */
export const unmaskNumber = (value: string): number => {
  const digits = value.replace(/\D/g, '');
  let output = 0;
  output = parseFloat(digits) / 100;
  return output;
};
