import { Box, Flex, Separator, Tabs, Text } from '@radix-ui/themes';
import { useParams } from 'react-router-dom';

import AccountForm from 'modules/companies/components/AccountForm';
import AddressForm from 'modules/companies/components/AddressForm';
import ManagementForm from 'modules/companies/components/ManagementForm';
import ReportsForm from 'modules/companies/components/ReportsForm';

import Container from 'components/Container';
import IconLoading from 'components/IconLoading';

import { useCompany } from 'services/companies';

const CompanieDetail = () => {
  const { id } = useParams();
  const { data, isLoading } = useCompany(id);

  return (
    <Container>
      {isLoading ? (
        <Flex align="center" justify="center" style={{ height: 300 }}>
          <IconLoading width="48" height="48" />
        </Flex>
      ) : (
        <>
          <Box>
            <Box mb="4">
              <Text>{data?.name}</Text>
            </Box>

            <Separator my="3" size="4" color="gray" />
          </Box>

          <Tabs.Root defaultValue="account">
            <Tabs.List>
              <Tabs.Trigger value="account">Dados da emrpesa</Tabs.Trigger>
              <Tabs.Trigger value="address" disabled={!data?.id}>
                Endereço
              </Tabs.Trigger>
              <Tabs.Trigger value="management" disabled={!data?.id}>
                Gestores
              </Tabs.Trigger>
              <Tabs.Trigger value="reports" disabled={!data?.id}>
                Tarefas
              </Tabs.Trigger>
            </Tabs.List>

            <Box style={{ maxWidth: 800 }} pt="6">
              <Tabs.Content value="account">
                <AccountForm />
              </Tabs.Content>
              <Tabs.Content value="address">
                <AddressForm />
              </Tabs.Content>
              <Tabs.Content value="management">
                <ManagementForm />
              </Tabs.Content>
              <Tabs.Content value="reports">
                <ReportsForm />
              </Tabs.Content>
            </Box>
          </Tabs.Root>
        </>
      )}
    </Container>
  );
};

export default CompanieDetail;
