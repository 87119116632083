/* eslint-disable import/no-duplicates */

import { useCallback, useMemo } from 'react';

import { useQueries } from '@tanstack/react-query';
import { endOfMonth, format, startOfMonth, subMonths } from 'date-fns';
import { ptBR } from 'date-fns/locale/pt-BR';

import { useCompanies } from 'services/companies';
import { Company } from 'services/companies/types';
import { queryTasks } from 'services/tasks';
import { TasksResponse } from 'services/tasks/types';

const KEY_CACHE = 'todo';

const useTodos = (category: Company['category'], search?: string) => {
  const { data, isPending } = useCompanies({
    page: 1,
    page_size: 100,
    category,
    order_by: 'trading_name',
  });

  const companies = useMemo(() => {
    return data?.results?.filter((i) => i.is_active === 'True') ?? [];
  }, [data]);

  const date = useMemo(() => new Date(), []);

  // Params default for filter query task
  const paramsDefault = useMemo(() => {
    return {
      page_size: 100,
      order_by: 'reference_date',
    };
  }, []);

  const convertData = useCallback(
    (id: Number, data: TasksResponse, referenceDate: string) => {
      return {
        company_id: id,
        reference_date: format(new Date(referenceDate), 'MMMM', {
          locale: ptBR,
        }),
        results: data.results.sort((a, b) => {
          return (
            new Date(a.due_date).getTime() - new Date(b.due_date).getTime()
          );
        }),
      };
    },
    [],
  );

  // current month
  const paramsCurrentMonth = useMemo(() => {
    return {
      ...paramsDefault,
      reference_date__gte: format(startOfMonth(date), 'yyyy-MM-dd'),
      reference_date__lte: format(endOfMonth(date), 'yyyy-MM-dd'),
    };
  }, [date, paramsDefault]);

  const queryCurrentMonth = useQueries({
    queries: companies?.map(({ id }) => ({
      gcTime: 10 * 60 * 1000,
      keepPreviousData: true,
      queryKey: [KEY_CACHE, id, paramsCurrentMonth],
      queryFn: () =>
        queryTasks({ company_id: id, ...paramsCurrentMonth }).then((results) =>
          convertData(id, results, paramsCurrentMonth.reference_date__lte),
        ),
    })),
    combine: (results) => {
      return {
        data: results.map((result) => result.data),
        pending: results.some((result) => result.isPending),
      };
    },
  });

  // one month ago
  const paramsOneMonthAgo = useMemo(() => {
    return {
      ...paramsDefault,
      reference_date__gte: format(
        startOfMonth(subMonths(date, 1)),
        'yyyy-MM-dd',
      ),
      reference_date__lte: format(endOfMonth(subMonths(date, 1)), 'yyyy-MM-dd'),
    };
  }, [date, paramsDefault]);

  const queryOneMonthAgo = useQueries({
    queries: companies?.map(({ id }) => ({
      gcTime: 10 * 60 * 1000,
      keepPreviousData: true,
      queryKey: [KEY_CACHE, id, paramsOneMonthAgo],
      queryFn: () =>
        queryTasks({ company_id: id, ...paramsOneMonthAgo }).then((results) =>
          convertData(id, results, paramsOneMonthAgo.reference_date__lte),
        ),
    })),
    combine: (results) => {
      return {
        data: results.map((result) => result.data),
        pending: results.some((result) => result.isPending),
      };
    },
  });

  // two month ago
  const paramsTwoMonthAgo = useMemo(() => {
    return {
      ...paramsDefault,
      reference_date__gte: format(
        startOfMonth(subMonths(date, 2)),
        'yyyy-MM-dd',
      ),
      reference_date__lte: format(endOfMonth(subMonths(date, 2)), 'yyyy-MM-dd'),
    };
  }, [date, paramsDefault]);

  const queryTwoMonthAgo = useQueries({
    queries: companies?.map(({ id }) => ({
      gcTime: 10 * 60 * 1000,
      keepPreviousData: true,
      queryKey: [KEY_CACHE, id, paramsTwoMonthAgo],
      queryFn: () =>
        queryTasks({ company_id: id, ...paramsTwoMonthAgo }).then((results) =>
          convertData(id, results, paramsTwoMonthAgo.reference_date__lte),
        ),
    })),
    combine: (results) => {
      return {
        data: results.map((result) => result.data),
        pending: results.some((result) => result.isPending),
      };
    },
  });

  const board = useMemo(() => {
    return companies.map((company) => {
      const dataCurrentMonth = queryCurrentMonth?.data?.filter(
        (item) => item?.company_id === company.id,
      )[0];
      const dataOneMonthAgo = queryOneMonthAgo?.data?.filter(
        (item) => item?.company_id === company.id,
      )[0];
      const dataTwoMonthAgo = queryTwoMonthAgo?.data?.filter(
        (item) => item?.company_id === company.id,
      )[0];

      return {
        id: company.id,
        name: company.name,
        trading_name: company.trading_name,
        is_active: company.is_active,
        tasks: [
          {
            reference: dataTwoMonthAgo?.reference_date.toUpperCase(),
            data: dataTwoMonthAgo?.results,
            pending: queryTwoMonthAgo.pending,
          },
          {
            reference: dataOneMonthAgo?.reference_date.toUpperCase(),
            data: dataOneMonthAgo?.results,
            pending: queryOneMonthAgo.pending,
          },
          {
            reference: dataCurrentMonth?.reference_date.toUpperCase(),
            data: dataCurrentMonth?.results,
            pending: queryCurrentMonth.pending,
          },
        ],
      };
    });
  }, [companies, queryCurrentMonth, queryOneMonthAgo, queryTwoMonthAgo]);

  const filtered = useMemo(() => {
    return search
      ? board.filter((i) =>
          `${i.name.toLowerCase()} ${i.trading_name.toLowerCase()}`.includes(
            search.toLowerCase(),
          ),
        )
      : board;
  }, [board, search]);

  return {
    board: filtered,
    count: filtered.length,
    isPending:
      isPending ||
      queryCurrentMonth.pending ||
      queryOneMonthAgo.pending ||
      queryTwoMonthAgo.pending,
  };
};

export default useTodos;
