import axios from 'axios';

import { CEP_API, ENERGYVIEW_API } from 'config/environment';

import { setupInterceptors } from './interceptors';

export const baseApi = axios.create({
  baseURL: ENERGYVIEW_API,
});

setupInterceptors(baseApi);

export const baseCep = axios.create({
  baseURL: CEP_API,
});
