import { ptBR } from 'date-fns/locale/pt-BR';
import { format } from 'date-fns-tz';

export const formatDate = (
  date?: number | string | Date,
  dateFormat = `dd/MM/yyyy`,
): string => {
  if (!date) {
    return ``;
  }

  return format(new Date(date), dateFormat, { locale: ptBR });
};
