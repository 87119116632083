import type { Ref, MutableRefObject } from 'react';
import { useMemo } from 'react';

function setRef<T>(
  ref:
    | MutableRefObject<T | null>
    | ((instance: T | null) => void)
    | null
    | undefined,
  value: T | null,
): void {
  if (typeof ref === `function`) {
    ref(value);
  } else if (ref) {
    // eslint-disable-next-line no-param-reassign
    ref.current = value;
  }
}

// ts-prune-ignore-next
export default function useForkRef<Instance, CustomInstance = Instance>(
  refA: Ref<Instance> | null | undefined,
  refB: Ref<CustomInstance | Instance> | null | undefined,
): Ref<Instance> | null {
  /**
   * This will create a new function if the ref props change and are defined.
   * This means react will call the old forkRef with `null` and the new forkRef
   * with the ref. Cleanup naturally emerges from this behavior.
   */
  return useMemo<Ref<Instance> | null>(() => {
    if (refA === null && refB === null) {
      return null;
    }
    return (refValue: Instance | null) => {
      setRef(refA, refValue);
      setRef(refB, refValue);
    };
  }, [refA, refB]);
}
