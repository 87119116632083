import { BackpackIcon, PersonIcon, RocketIcon } from '@radix-ui/react-icons';
import {
  Box,
  Card,
  Flex,
  Grid,
  Heading,
  Skeleton,
  Text,
} from '@radix-ui/themes';

import { useCompanies } from 'services/companies';
import { useContacts } from 'services/contacts';
import { useManagers } from 'services/managers';
import { useCurrentUser } from 'services/user';

const Dashboard = () => {
  const { data: customer, isPending: isPendingCurrentUser } = useCurrentUser();
  const { data: companies, isPending: isPendingCompanies } = useCompanies({
    page: 1,
    page_size: 1000,
  });

  const { data: contacts, isPending: isPendingContacts } = useContacts({
    page: 1,
    page_size: 1000,
  });
  const { data: managers, isPending: isPendingManagers } = useManagers();

  return (
    <Box>
      <Text as="p" size="8" weight="medium">
        <Skeleton loading={isPendingCurrentUser}>
          Olá {customer?.me.name},
        </Skeleton>
      </Text>
      <Text as="p">Isso é o que temos para você hoje.</Text>

      <Grid gap="4" mt="8" columns="4">
        <Card>
          <Flex align="end" justify="between">
            <Box>
              <Heading size="6" weight="medium" color="gray">
                Clientes
              </Heading>

              <Box mt="4">
                <Text size="9" weight="medium">
                  <Skeleton
                    height="60.5px"
                    maxWidth="80px"
                    loading={isPendingCompanies}
                  >
                    {companies?.count}
                  </Skeleton>
                </Text>
              </Box>
            </Box>

            <Box>
              <RocketIcon width={60} height={60} color="#003eeb26" />
            </Box>
          </Flex>
        </Card>

        <Card>
          <Flex align="end" justify="between">
            <Box>
              <Heading size="6" weight="medium" color="gray">
                Contatos
              </Heading>

              <Box mt="4">
                <Text size="9" weight="medium">
                  <Skeleton
                    height="60.5px"
                    maxWidth="80px"
                    loading={isPendingContacts}
                  >
                    {contacts?.count}
                  </Skeleton>
                </Text>
              </Box>
            </Box>

            <Box>
              <PersonIcon width={60} height={60} color="#003eeb26" />
            </Box>
          </Flex>
        </Card>

        <Card>
          <Flex align="end" justify="between">
            <Box>
              <Heading size="6" weight="medium" color="gray">
                Gestores
              </Heading>

              <Box mt="4">
                <Text size="9" weight="medium">
                  <Skeleton
                    height="60.5px"
                    maxWidth="80px"
                    loading={isPendingManagers}
                  >
                    {managers?.count}
                  </Skeleton>
                </Text>
              </Box>
            </Box>

            <Box>
              <BackpackIcon width={60} height={60} color="#003eeb26" />
            </Box>
          </Flex>
        </Card>
      </Grid>
    </Box>
  );
};

export default Dashboard;
