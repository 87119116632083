import { forwardRef, useCallback, useMemo, useRef, useState } from 'react';

import { Cross2Icon, FileIcon, UploadIcon } from '@radix-ui/react-icons';
import { Box, Card, Flex, Link, Text, Tooltip } from '@radix-ui/themes';

import InputError from 'components/InputError';
import InputLabel from 'components/InputLabel';

import useForkRef from 'utils/useForkRef';

import { HandlerFileStatus, InputFileProps } from './types';

import './styles.css';

const InputFile = forwardRef<HTMLInputElement, InputFileProps>((props, ref) => {
  const { error, onChange } = props;

  const [file, setFile] = useState<File>();
  const [dragStatus, setDragStatus] = useState<HandlerFileStatus | null>(null);

  const inputInternalRef = useRef<HTMLInputElement>(null);
  const handleInputRef = useForkRef<HTMLInputElement>(inputInternalRef, ref);

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;

      if (files && files.length > 0) {
        setFile(files[0]);
      }

      onChange?.(event);
    },
    [onChange],
  );

  const borderColorStatus = useMemo(() => {
    if (error) {
      return 'var(--red-11)';
    }

    return dragStatus === HandlerFileStatus.DRAG
      ? 'var(--accent-8)'
      : 'var(--gray-7)';
  }, [dragStatus, error]);

  const handleRemoveFile = useCallback(() => {
    inputInternalRef!.current!.value = '';
    setFile(undefined);
  }, []);

  return (
    <Box mb="5">
      <InputLabel text="Anexo" />
      <Flex
        className="file-upload"
        position="relative"
        align="center"
        justify="center"
        p="6"
        style={{
          borderColor: borderColorStatus,
        }}
      >
        <Flex align="center" justify="center" direction="column">
          <Box p="4">
            <UploadIcon width="36" height="36" color="gray" />
          </Box>
          <Text color="gray" size="2">
            Arraste o arquivo aqui ou{' '}
            <Link color="blue">click para fazer o upload</Link>
          </Text>
        </Flex>

        <input
          type="file"
          ref={handleInputRef as React.Ref<HTMLInputElement>}
          className="input-file"
          accept="image/*,.pdf"
          onDragOver={() => setDragStatus(HandlerFileStatus.DRAG)}
          onDragLeave={() => setDragStatus(HandlerFileStatus.DROP)}
          onDrop={() => setDragStatus(HandlerFileStatus.DROP)}
          multiple={false}
          {...props}
          onChange={handleFileChange}
        />
      </Flex>

      {!error && (
        <Text color="gray" size="1">
          Permitido apenas arquivos com extensão jpg, png ou pdf
        </Text>
      )}
      {error && <InputError error={error} />}

      {inputInternalRef.current?.value && (
        <Card mt="4">
          <Flex gap="3" align="center" justify="between">
            <Flex gap="3" align="center">
              <FileIcon width="24" height="24" color="gray" />

              <Box>
                <Text as="div" size="2" weight="bold" color="gray">
                  {file?.name}
                </Text>
                <Text as="div" size="2" color="gray">
                  {file?.type}
                </Text>
              </Box>
            </Flex>

            <Tooltip content="Excluir anexo">
              <Cross2Icon
                width="20"
                height="20"
                cursor="pointer"
                color="red"
                onClick={handleRemoveFile}
              />
            </Tooltip>
          </Flex>
        </Card>
      )}
    </Box>
  );
});

InputFile.displayName = 'InputFile';

export default InputFile;
