import { useMemo, useState } from 'react';

import { Pencil1Icon, TrashIcon } from '@radix-ui/react-icons';
import {
  Badge,
  Box,
  Button,
  Flex,
  IconButton,
  Spinner,
  Table,
  Tooltip,
} from '@radix-ui/themes';
import { useParams } from 'react-router-dom';

import RemoveContactAlert from 'modules/companies/components//RemoveContactAlert';
import ContactDialog from 'modules/companies/components/ContactDialog';

import InputSearch from 'components/InputSearch';

import { useCompanyContacts } from 'services/companies';

import { formatString } from 'utils/mask/formatString';

const ReportsForm = () => {
  const [search, setSearch] = useState<string>();
  const { id } = useParams();

  const { data, isLoading } = useCompanyContacts(id);

  const contacts = useMemo(() => {
    return (
      data?.results.filter((i) =>
        i.name.toLowerCase().includes((search ?? '').toLowerCase()),
      ) ?? []
    );
  }, [data?.results, search]);

  return (
    <Box>
      {isLoading ? (
        <Flex align="center" justify="center" style={{ height: 300 }}>
          <Spinner size="3" />
        </Flex>
      ) : (
        <>
          <Flex gap="4" align="center" mb="4">
            <InputSearch
              placeholder="Buscar Contato"
              onChange={(value) => setSearch(value)}
              total={data?.results.length ?? 0}
            />

            <ContactDialog
              companyId={id as string}
              trigger={<Button>Adicionar</Button>}
            />
          </Flex>

          <Table.Root>
            <Table.Header>
              <Table.Row>
                <Table.ColumnHeaderCell>Nome</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>E-mail</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Telefone</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Status</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell></Table.ColumnHeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {contacts.map((contact) => (
                <Table.Row key={contact.id} align="center">
                  <Table.RowHeaderCell>{contact.name}</Table.RowHeaderCell>
                  <Table.Cell>{contact.email}</Table.Cell>
                  <Table.Cell>
                    {formatString(contact.phone, '(dd) ddddd-dddd')}
                  </Table.Cell>
                  <Table.Cell>
                    <Badge color={contact.is_active ? 'green' : 'red'}>
                      {contact.is_active ? 'Ativo' : 'Inativo'}
                    </Badge>
                  </Table.Cell>
                  <Table.Cell>
                    <Flex gap="4">
                      <Tooltip content="Excluir">
                        <RemoveContactAlert
                          companyId={id as string}
                          data={contact}
                          trigger={
                            <IconButton size="1" variant="soft" color="red">
                              <TrashIcon width={14} height={14} />
                            </IconButton>
                          }
                        />
                      </Tooltip>

                      <Tooltip content="Editar">
                        <ContactDialog
                          companyId={id as string}
                          contactId={contact.id}
                          trigger={
                            <IconButton size="1" variant="soft">
                              <Pencil1Icon width={14} height={14} />
                            </IconButton>
                          }
                        />
                      </Tooltip>
                    </Flex>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table.Root>
        </>
      )}
    </Box>
  );
};
export default ReportsForm;
