import { useCallback, useEffect, useRef } from 'react';

import {
  AlertDialog,
  Button,
  Flex,
  RadioGroup,
  Strong,
  Switch,
  Text,
} from '@radix-ui/themes';
import { useQueryClient } from '@tanstack/react-query';
import { Toast } from 'primereact/toast';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import IconLoading from 'components/IconLoading';
import InputField from 'components/InputField';
import TextAreaField from 'components/TextAreaField';

import { PAGE_SIZE } from 'config/constants';
import { ROUTES } from 'config/routes';

import useNavigate from 'hooks/useNavigate';

import {
  useCompany,
  useCreateCompany,
  useDeleteCompany,
  useUpdateCompany,
} from 'services/companies';
import { createUseCompanies } from 'services/companies/keys';

import { Category, FormData } from './types';

const AccountForm = () => {
  const { id } = useParams();
  const { navigate } = useNavigate();

  const queryClient = useQueryClient();

  const toastBC = useRef<Toast>(null);

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      is_active: 'True',
      category: Category.LIVRE,
    },
  });

  const { data } = useCompany(id);
  const { mutate: updateCompany, isPending: isPendingUpdateCompany } =
    useUpdateCompany();
  const { mutate: createCompany, isPending: isPendingCreateCompany } =
    useCreateCompany();
  const { mutate: deleteCompany, isPending: isPendingDeleteCompany } =
    useDeleteCompany();

  const isLoading = isPendingUpdateCompany || isPendingCreateCompany;

  const isActive = watch('is_active');
  const category = watch('category');

  useEffect(() => {
    if (data) {
      setValue('is_active', data.is_active);
      setValue('category', data.category);
      setValue('code', data.code);
      setValue('name', data.name);
      setValue('trading_name', data.trading_name);
      setValue('cnpj', data.cnpj);
      setValue('ie', data.ie);
      setValue('im', data.im);
      setValue('id', data.id);
      setValue('obs', data.obs);
      setValue('website', data.website);
      setValue('segment', data.segment);
    }
  }, [data, setValue]);

  const onSubmit = useCallback(
    (formData: FormData) => {
      if (data?.id && id) {
        updateCompany(formData, {
          onSuccess() {
            toastBC.current?.show({
              severity: 'success',
              summary: 'Empresa',
              detail: 'Dados da empresa atualizado com sucesso.',
              life: 5000,
            });
          },
          onError() {
            toastBC.current?.show({
              severity: 'error',
              summary: 'Empresa',
              detail: 'Erro ao atualizar cadastro.',
              life: 5000,
            });
          },
        });
      } else {
        createCompany(formData, {
          onSuccess(data) {
            toastBC.current?.show({
              severity: 'success',
              summary: 'Empresa',
              detail: 'Novo cliente cadastrado com sucesso.',
              life: 5000,
            });

            queryClient.invalidateQueries({
              queryKey: createUseCompanies({
                page: 1,
                page_size: PAGE_SIZE,
              }),
            });

            navigate(`${ROUTES.PRIVATE.COMPANIES}/${data.id}`);
          },
          onError() {
            toastBC.current?.show({
              severity: 'error',
              summary: 'Empresa',
              detail: 'Erro ao cadastrar novo cliente.',
              life: 5000,
            });
          },
        });
      }
    },
    [createCompany, data?.id, id, navigate, queryClient, updateCompany],
  );

  const removeCompany = useCallback(() => {
    if (data?.id) {
      deleteCompany(
        {
          id: data.id,
        },
        {
          onSuccess(data) {
            toastBC.current?.show({
              severity: 'success',
              summary: 'Empresa',
              detail: 'Cliente removido com sucesso.',
              life: 5000,
            });

            navigate(`${ROUTES.PRIVATE.COMPANIES}`);
          },
          onError() {
            toastBC.current?.show({
              severity: 'error',
              summary: 'Empresa',
              detail: 'Erro ao remover cliente.',
              life: 5000,
            });
          },
        },
      );
    }
  }, [data?.id, deleteCompany, navigate]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Text as="label" size="2">
        <Flex gap="2" mb="4">
          <Switch
            defaultChecked={data?.is_active === 'True'}
            checked={isActive === 'True'}
            onCheckedChange={(checked) =>
              setValue('is_active', checked ? 'True' : 'False')
            }
          />{' '}
          Ativo
        </Flex>
      </Text>

      <RadioGroup.Root
        onValueChange={(value: Category) => setValue('category', value)}
        defaultValue={data?.category}
        value={category}
      >
        <Text as="p" mb="2">
          Categoria
        </Text>
        <Flex gap="2" direction="row" mb="4">
          <Text as="label">
            <Flex gap="2" align="center">
              <RadioGroup.Item value={Category.LIVRE} /> Livre
            </Flex>
          </Text>
          <Text as="label">
            <Flex gap="2" align="center">
              <RadioGroup.Item value={Category.VAREJISTA} /> Varejista
            </Flex>
          </Text>
        </Flex>
      </RadioGroup.Root>

      <InputField
        label="Código"
        error={errors.code?.message}
        {...register('code', { required: 'Código é obrigatório' })}
      />
      <InputField
        label="Empresa"
        error={errors.name?.message}
        {...register('name', { required: 'Empresa é obrigatório' })}
      />
      <InputField
        label="Agente"
        error={errors.trading_name?.message}
        {...register('trading_name', {
          required: 'Agente é obrigatório',
        })}
      />
      <InputField
        label="CNPJ"
        error={errors.cnpj?.message}
        mask="dd.ddd.ddd/dddd-dd"
        {...register('cnpj', { required: 'CNPJ é obrigatório' })}
      />
      <Flex gap="4">
        <InputField
          label="I.E."
          error={errors.ie?.message}
          {...register('ie')}
        />
        <InputField
          label="I.M."
          error={errors.im?.message}
          {...register('im')}
        />
      </Flex>

      <InputField
        label="Site"
        error={errors.website?.message}
        {...register('website')}
      />
      <InputField
        label="Segmento/Ramo"
        error={errors.segment?.message}
        {...register('segment')}
      />
      <TextAreaField label="Observações" {...register('obs')} />

      <Flex gap="4">
        <Flex
          style={{
            flex: 1,
          }}
        >
          {data?.id && data?.name && (
            <AlertDialog.Root>
              <AlertDialog.Trigger>
                <Button color="crimson" variant="soft" type="button">
                  Excluir
                </Button>
              </AlertDialog.Trigger>
              <AlertDialog.Content maxWidth="450px">
                <AlertDialog.Title>Atenção</AlertDialog.Title>
                <AlertDialog.Description size="2">
                  Você está prestes a excluir um cliente. Tem certeza de deseja
                  excluir o(a) <Strong>{data.name}</Strong>?
                </AlertDialog.Description>

                <Flex gap="3" mt="4" justify="end">
                  <AlertDialog.Cancel>
                    <Button variant="soft" color="gray">
                      Cancelar
                    </Button>
                  </AlertDialog.Cancel>
                  <AlertDialog.Action>
                    <Button
                      variant="solid"
                      color="red"
                      onClick={removeCompany}
                      loading={isPendingDeleteCompany}
                    >
                      Excluir
                    </Button>
                  </AlertDialog.Action>
                </Flex>
              </AlertDialog.Content>
            </AlertDialog.Root>
          )}
        </Flex>

        <Flex justify="end">
          <Button size="3" type="submit" disabled={isLoading}>
            {isLoading && <IconLoading />} Salvar
          </Button>
        </Flex>
      </Flex>

      <Toast ref={toastBC} />
    </form>
  );
};

export default AccountForm;
