import type {
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';

import type { ApiError, ApiResponse } from 'entities/Response';

import { baseApi } from 'libs/axios';

import parseData from 'utils/parseData';

import { createUseEmployee, createUseStaffs } from './keys';
import type {
  EmployeeData,
  EmployeeResponse,
  StaffsParams,
  StaffsResponse,
} from './types';

export const useStaffs = (
  params: StaffsParams,
  options?: Partial<UseQueryOptions<StaffsResponse, ApiError>>,
) => {
  return useQuery({
    queryKey: createUseStaffs(params),
    queryFn: ({ pageParam = params?.page ?? 1 }) =>
      baseApi
        .get<ApiResponse<StaffsResponse>>(`staffs`, {
          params: {
            page: pageParam,
            ...params,
          },
        })
        .then(parseData),
    ...options,
  });
};

export const useEmployee = (
  id: string,
  options?: Partial<UseQueryOptions<EmployeeResponse, ApiError>>,
) => {
  return useQuery({
    queryKey: createUseEmployee(id),
    queryFn: () =>
      baseApi
        .get<ApiResponse<EmployeeResponse>>(`staffs/${id}`)
        .then(parseData),
    ...options,
  });
};

export const useCreateEmployee = (
  options?: Partial<
    UseMutationOptions<EmployeeResponse, ApiError, EmployeeData>
  >,
) => {
  return useMutation({
    mutationFn: (data) =>
      baseApi
        .post<ApiResponse<EmployeeResponse>>(`staffs`, data)
        .then(parseData),
    ...options,
  });
};

export const useUpdateEmployee = (
  options?: Partial<
    UseMutationOptions<EmployeeResponse, ApiError, Partial<EmployeeResponse>>
  >,
) => {
  return useMutation({
    mutationFn: (data) =>
      baseApi
        .put<ApiResponse<EmployeeResponse>>(`staffs/${data.id}`, data)
        .then(parseData),
    ...options,
  });
};

export const useDeleteEmployee = (
  options?: Partial<
    UseMutationOptions<EmployeeResponse, ApiError, Partial<EmployeeResponse>>
  >,
) => {
  return useMutation({
    mutationFn: (data) =>
      baseApi
        .delete<ApiResponse<EmployeeResponse>>(`staffs/${data.id}`)
        .then(parseData),
    ...options,
  });
};

// export const useSystemUser = (
//   userId: string,
//   options?: Partial<UseQueryOptions<SystemUserResponse, ApiError>>,
// ) => {
//   return useQuery({
//     queryKey: createUseSystemUser(userId),
//     queryFn: () =>
//       baseApi
//         .get<ApiResponse<SystemUserResponse>>(`users/${userId}`)
//         .then(parseData),
//     ...options,
//   });
// };
