import { formatNumber } from 'utils/mask/formatNumber';

export const formatCurrency = (
  value: number,
  options?: Intl.NumberFormatOptions,
): string => {
  return formatNumber(value, {
    currency: 'BRL',
    style: 'currency',
    ...options,
  });
};
