import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Box, Button, Checkbox, Flex, Table } from '@radix-ui/themes';
import { Toast } from 'primereact/toast';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import IconLoading from 'components/IconLoading';
import InputSearch from 'components/InputSearch';

import { useCompany, useUpdateCompany } from 'services/companies';
import { useManagers } from 'services/managers';

import { FormData } from './types';

const ManagementForm = () => {
  const [search, setSearch] = useState<string>();

  const { id } = useParams();

  const toastBC = useRef<Toast>(null);

  const { data, isLoading: isLoadingManagers } = useManagers({
    page_size: 200,
  });
  const { data: company } = useCompany(id);
  const { mutate, isPending: isPendingUpdateCompany } = useUpdateCompany();

  const { setValue, getValues, handleSubmit } = useForm<FormData>();

  const managers = useMemo(() => {
    return data?.results.filter((i) =>
      i.name.toLowerCase().includes(search?.toLowerCase() ?? ''),
    );
  }, [data, search]);

  const handleOnChange = useCallback(
    (value: number) => {
      const managers = getValues('manager_ids');
      const isChecked = managers.includes(value);

      if (isChecked) {
        setValue(
          'manager_ids',
          managers.filter((c) => c !== value),
        );
      } else {
        setValue('manager_ids', [...managers, value]);
      }
    },
    [getValues, setValue],
  );

  const onSubmit = useCallback(
    (formData: FormData) => {
      mutate(
        {
          id: company?.id ?? 0,
          manager_ids: formData.manager_ids,
        },
        {
          onSuccess() {
            toastBC.current?.show({
              severity: 'success',
              summary: 'Gestores da empresa',
              detail: 'Gestores da empresa atualizado com sucesso.',
              life: 5000,
            });
          },
          onError() {
            toastBC.current?.show({
              severity: 'error',
              summary: 'Gestores',
              detail: 'Erro ao atualizar cadastro.',
              life: 5000,
            });
          },
        },
      );
    },
    [company?.id, mutate],
  );

  useEffect(() => {
    if (company?.manager_ids) {
      setValue('manager_ids', company.manager_ids);
    }
  }, [company, setValue]);

  return (
    <Box>
      {isLoadingManagers ? (
        <Flex align="center" justify="center" style={{ height: 300 }}>
          <IconLoading width="48" height="48" />
        </Flex>
      ) : (
        <>
          <Box mb="4">
            <InputSearch
              placeholder="Buscar Gestores"
              onChange={(value) => setSearch(value)}
              total={data?.count ?? 0}
            />
          </Box>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Table.Root>
              <Table.Header>
                <Table.Row>
                  <Table.ColumnHeaderCell></Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell>Nome</Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell>E-mail</Table.ColumnHeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {managers?.map((manager) => (
                  <Table.Row key={manager.id} align="center">
                    <Table.RowHeaderCell>
                      <Checkbox
                        size="2"
                        defaultChecked={company?.manager_ids.includes(
                          manager.id,
                        )}
                        onCheckedChange={() => {
                          handleOnChange(manager.id);
                        }}
                        value={manager.id}
                      />
                    </Table.RowHeaderCell>
                    <Table.Cell>{manager.name}</Table.Cell>
                    <Table.Cell>{manager.email}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table.Root>
            <Flex justify="end" mt="4">
              <Button size="3" type="submit" disabled={isPendingUpdateCompany}>
                {isPendingUpdateCompany && <IconLoading />} Salvar
              </Button>
            </Flex>
          </form>
        </>
      )}

      <Toast ref={toastBC} />
    </Box>
  );
};

export default ManagementForm;
