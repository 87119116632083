import { InputHTMLAttributes } from 'react';

export type InputFileProps = InputHTMLAttributes<HTMLInputElement> & {
  error?: string;
};

export enum HandlerFileStatus {
  DRAG = 'DRAG',
  DROP = 'DROP',
  BLANK = 'BLANK',
}
