import { useParams } from 'react-router-dom';

import Container from 'components/Container';

import { useContact } from 'services/contacts';

const ContactDetail = () => {
  const { id } = useParams();
  useContact(id as string);

  return <Container>Contato</Container>;
};

export default ContactDetail;
