import { useMemo, useState } from 'react';

import { Pencil1Icon } from '@radix-ui/react-icons';
import {
  Badge,
  Button,
  Flex,
  IconButton,
  Skeleton,
  Table,
  Tooltip,
} from '@radix-ui/themes';

import Container from 'components/Container';
import InputSearch from 'components/InputSearch';
import Pagination from 'components/Pagination';

import { PAGE_SIZE } from 'config/constants';
import { ROUTES } from 'config/routes';

import useNavigate from 'hooks/useNavigate';

import { useStaffs } from 'services/staffs';

const Staffs = () => {
  const { navigate } = useNavigate();
  const [search, setSearch] = useState<string>();
  const [page, setPage] = useState<number>(1);
  const { data, isPending } = useStaffs({
    page,
    page_size: PAGE_SIZE,
    order_by: 'name',
  });

  const stafss = useMemo(() => {
    return data?.results.filter((i) =>
      (i.name.toLowerCase() || i.email.toLowerCase()).includes(
        search?.toLowerCase() ?? '',
      ),
    );
  }, [data, search]);

  return (
    <Container>
      <Flex mb="4" align="center" justify="between">
        <InputSearch
          placeholder="Buscar membro da equipe"
          onChange={(value) => setSearch(value)}
          total={data?.count ?? 0}
          disabled={isPending}
          isLoading={isPending}
        />

        <Skeleton loading={isPending}>
          <Button onClick={() => navigate(ROUTES.PRIVATE.STAFFS_CREATE)}>
            Adicionar
          </Button>
        </Skeleton>
      </Flex>

      <Table.Root>
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeaderCell>Nome</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>E-mail</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Acesso</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell></Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {isPending
            ? Array(10)
                .fill('')
                .map((_, i) => (
                  <Table.Row key={`loading_${i}`}>
                    <Table.Cell>
                      <Skeleton minWidth="200px" />
                    </Table.Cell>
                    <Table.Cell>
                      <Skeleton minWidth="250px" />
                    </Table.Cell>
                    <Table.Cell>
                      <Skeleton minWidth="150px" />
                    </Table.Cell>
                    <Table.Cell>
                      <Skeleton />
                    </Table.Cell>
                  </Table.Row>
                ))
            : stafss?.map((user) => (
                <Table.Row key={user.id} align="center">
                  <Table.RowHeaderCell>{user.name}</Table.RowHeaderCell>
                  <Table.Cell>{user.email}</Table.Cell>
                  <Table.Cell>
                    <Badge color={user.user_id ? 'green' : 'red'}>
                      {user.user_id ? 'Com acesso' : 'Sem acesso'}
                    </Badge>
                  </Table.Cell>

                  <Table.Cell>
                    <Tooltip content="Editar">
                      <IconButton size="1" variant="soft">
                        <Pencil1Icon
                          width={14}
                          height={14}
                          onClick={() =>
                            navigate(`${ROUTES.PRIVATE.STAFFS}/${user.id}`)
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </Table.Cell>
                </Table.Row>
              ))}
        </Table.Body>
      </Table.Root>

      <Pagination
        num_pages={data?.num_pages ?? 0}
        onChange={(page) => setPage(page)}
      />
    </Container>
  );
};

export default Staffs;
