import type { QueryKey } from '@tanstack/react-query';

import { ContactsParams } from './types';

export const createUseContacts = (params?: ContactsParams): QueryKey => [
  `useContacts`,
  params,
];

export const createUseContact = (contactId: string): QueryKey => [
  'useContact',
  contactId,
];
