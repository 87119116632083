import { unmaskString } from 'utils/mask/unmaskString';

import { tokens } from './tokens';

export const formatString = (
  value: string,
  patterns: string | string[],
): string => {
  if (!value) return '';

  let pattern = '';

  // Multiple mask
  if (Array.isArray(patterns)) {
    // Expected: ['#', '##', '###', '####'] to pick the best mask
    const sorted = patterns.sort((a, b) => a.length - b.length);
    const unmaskedSize = unmaskString(value).length;
    const dynamicPattern = sorted.find(
      (mask) => unmaskedSize <= unmaskString(mask).length,
    );
    pattern = dynamicPattern ?? patterns[patterns.length - 1];
  } else {
    // Single mask
    pattern = patterns;
  }

  let output = '';

  for (
    let unmaskedValue = unmaskString(value, pattern),
      unmaskedPattern = unmaskString(pattern),
      patternLength = pattern.length,
      i = 0,
      ii = 0;
    i < patternLength && unmaskedValue[ii];
    i++
  ) {
    const token = tokens[unmaskedPattern[ii]];
    const patternChar = pattern[i];
    const inputChar = unmaskedValue[ii];

    if (!token?.test(inputChar)) break;
    else if (/\W/.test(patternChar)) output += patternChar;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, no-sequences
    else (output += token.transform(inputChar)), ii++;
  }

  return output;
};
