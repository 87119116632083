import { useCallback } from 'react';

import { useNavigate as useNavigateReact } from 'react-router-dom';

import { ROUTES } from 'config/routes';

const useNavigate = () => {
  const n = useNavigateReact();

  const navigate = useCallback(
    (path: string) => {
      n(`/${ROUTES.PRIVATE.ROOT}/${path}`);
    },
    [n],
  );

  return {
    navigate,
  };
};

// ts-prune-ignore-next
export default useNavigate;
