import { Box, Flex, Text } from '@radix-ui/themes';

import TaskPreview from 'modules/tasks/components/TaskPreview';

import { TaskReferenceMonthProps } from './types';

const { v4: uuidv4 } = require('uuid');

const TaskReferenceMonth = ({
  company,
  tasks,
  reference,
}: TaskReferenceMonthProps) => {
  return (
    <Flex py="1" align="center">
      <Box style={{ width: 100 }}>
        <Text style={{ textTransform: 'capitalize' }}>{reference}</Text>
      </Box>

      <Flex gap="2">
        {tasks?.map((task) => (
          <TaskPreview
            key={uuidv4()}
            task={task}
            company={company}
            reference={reference}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default TaskReferenceMonth;
