import { Box, Flex } from '@radix-ui/themes';
import Lottie from 'lottie-react';
import { Outlet } from 'react-router-dom';

import AnimationDashboard from './dashboard-2.json';

import './style.css';

const Auth = () => {
  return (
    <Flex className="container" align="center" justify="between">
      <Flex
        className="wrapper-signin"
        position="relative"
        align="center"
        justify="center"
      >
        <Outlet />
      </Flex>

      <Box className="wrapper-animation">
        <Lottie animationData={AnimationDashboard} />
      </Box>
    </Flex>
  );
};

export default Auth;
