import { useCallback } from 'react';

import { Box, Flex, Heading, Separator, Table } from '@radix-ui/themes';

import Container from 'components/Container';
import IconLoading from 'components/IconLoading';

import { MailRecipientsType } from 'services/mails/types';
import { useTaskMail } from 'services/tasks';

import { RecipientsProps } from './types';

const Recipients = ({ taskId }: RecipientsProps) => {
  const { data: mail, isLoading } = useTaskMail(taskId);

  const parsedGroup = useCallback((type: MailRecipientsType) => {
    const group: { [key in MailRecipientsType]: string } = {
      contact: 'Contato',
      manager: 'Gestão',
      support: 'Suporte',
    };

    return group[type];
  }, []);

  return (
    <Box mt="4">
      <Container>
        <Flex py="4" gap="4">
          <Heading size="4" color="gray">
            Destinatários
          </Heading>
        </Flex>

        <Separator my="3" size="4" />

        {isLoading ? (
          <Flex align="center" justify="center">
            <Box p="4">
              <IconLoading />
            </Box>
          </Flex>
        ) : (
          <Table.Root>
            <Table.Header>
              <Table.Row>
                <Table.ColumnHeaderCell>Nome</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Email</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Grupo</Table.ColumnHeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {mail?.to_do_recipients.map((contact) => (
                <Table.Row key={contact.email}>
                  <Table.RowHeaderCell>{contact.name}</Table.RowHeaderCell>
                  <Table.Cell>{contact.email}</Table.Cell>
                  <Table.Cell>{parsedGroup(contact.type)}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table.Root>
        )}
      </Container>
    </Box>
  );
};

export default Recipients;
