import { forwardRef, useCallback } from 'react';

import { Box, TextField } from '@radix-ui/themes';

import InputError from 'components/InputError';
import InputLabel from 'components/InputLabel';

import { formatCurrency } from 'utils/mask/formatCurrency';
import { formatString } from 'utils/mask/formatString';
import { unmaskNumber } from 'utils/mask/unmaskNumber';

import { InputFieldProps } from './types';

const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (props, ref) => {
    const {
      mask: maskPattern,
      currency,
      onChange,
      label,
      error,
      startAdornment,
      endAdornment,
    } = props;

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (maskPattern) {
          e.target.value = formatString(e.target.value, maskPattern);
        }

        if (currency) {
          const unmaskedNumber = unmaskNumber(e.target.value);
          e.target.value =
            unmaskedNumber === 0 || isNaN(unmaskedNumber)
              ? ''
              : formatCurrency(unmaskedNumber);
        }

        onChange?.(e);
      },
      [maskPattern, currency, onChange],
    );

    return (
      <Box mb="5" width="100%">
        {label && <InputLabel text={label} />}

        <TextField.Root
          ref={ref}
          size="3"
          radius="medium"
          color={error ? 'red' : 'blue'}
          {...props}
          onChange={handleChange}
        >
          {startAdornment && (
            <TextField.Slot side="left">{startAdornment}</TextField.Slot>
          )}
          {endAdornment && (
            <TextField.Slot side="right">{endAdornment}</TextField.Slot>
          )}
        </TextField.Root>

        {error && <InputError error={error} />}
      </Box>
    );
  },
);

InputField.displayName = 'InputMask';

export default InputField;
