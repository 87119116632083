import { useCallback, useEffect, useRef } from 'react';

import {
  AlertDialog,
  Button,
  Flex,
  RadioGroup,
  Spinner,
  Strong,
  Switch,
  Text,
} from '@radix-ui/themes';
import { Toast } from 'primereact/toast';
import { Controller, useForm } from 'react-hook-form';

import InputError from 'components/InputError';
import InputField from 'components/InputField';

import { useUpdateEmployee } from 'services/staffs';
import {
  useCreateSystemUser,
  useDeleteystemUser,
  useSystemUser,
  useUpdateSystemUser,
} from 'services/user';
import { RolePermission } from 'services/user/types';

import { FormData, UserAccessFormProps } from './types';

const UserAccessForm = ({ data }: UserAccessFormProps) => {
  const { data: user, refetch } = useSystemUser(
    (data?.user_id ?? '').toString(),
    {
      enabled: !!data?.user_id,
    },
  );

  const { mutate: updateUser, isPending: isPendingUpdateUser } =
    useUpdateSystemUser(user?.id);

  const { mutate: createUser, isPending: isPendingCreateUser } =
    useCreateSystemUser();

  const { mutate: updateEmployee, isPending: isPendingUpdateEmployee } =
    useUpdateEmployee();

  const { mutate: deleteUser, isPending: isPendingDeleteUser } =
    useDeleteystemUser(user?.id);

  const isLoading =
    isPendingUpdateUser || isPendingCreateUser || isPendingUpdateEmployee;

  const toastBC = useRef<Toast>(null);

  const { register, setValue, handleSubmit, control } = useForm<FormData>();

  useEffect(() => {
    if (user?.id) {
      setValue('phone', user.phone);
      setValue('role.company_permission', user.role.company_permission);
      setValue('role.mailing_permission', user.role.mailing_permission);
      setValue('role.task_permission', user.role.task_permission);
      setValue('role.user_permission', user.role.user_permission);
      setValue('company_ids', user.company_ids);
      setValue('is_active', user.is_active);
    } else {
      setValue('is_active', true);
    }

    if (data.id) {
      setValue('email', data.email);
      setValue('name', data.name);
    }
  }, [user, setValue, data]);

  const onSubmit = useCallback(
    (formData: FormData) => {
      if (user?.id) {
        updateUser(
          {
            ...formData,
          },
          {
            onSuccess() {
              toastBC.current?.show({
                severity: 'success',
                summary: 'Equipe',
                detail: 'Usuário atualizado com sucesso.',
                life: 5000,
              });
            },
            onError() {
              toastBC.current?.show({
                severity: 'error',
                summary: 'Equipe',
                detail: 'Erro ao atualizar usuário.',
                life: 5000,
              });
            },
          },
        );
      } else {
        createUser(
          {
            ...formData,
          },
          {
            onSuccess({ id }) {
              updateEmployee({
                id: data.id,
                user_id: id,
                cpf: data.cpf,
                email: data.email,
                name: data.name,
                rg: data.rg,
              });

              refetch();
            },
          },
        );
      }
    },
    [createUser, data, refetch, updateEmployee, updateUser, user?.id],
  );

  const onDeleteUser = useCallback(() => {
    deleteUser(undefined, {
      onSuccess() {
        toastBC.current?.show({
          severity: 'success',
          summary: 'Equipe',
          detail: 'Usuário removido com sucesso.',
          life: 5000,
        });

        updateEmployee(
          {
            id: data.id,
            user_id: null,
            cpf: data.cpf,
            email: data.email,
            name: data.name,
            rg: data.rg,
          },
          {
            onSuccess() {
              window.location.reload();
            },
          },
        );
      },
    });
  }, [data, deleteUser, updateEmployee]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="is_active"
        render={({ field }) => (
          <Text as="label" size="2">
            <Flex gap="2" mb="4">
              <Switch
                defaultChecked={field.value}
                checked={field.value}
                onCheckedChange={field.onChange}
              />{' '}
              Ativo
            </Flex>
          </Text>
        )}
      />

      <InputField label="Nome" readOnly {...register('name')} />

      <InputField label="E-mail" readOnly {...register('email')} />

      <InputField
        label="Celular"
        mask="(dd) ddddd-dddd"
        {...register('phone')}
      />

      <Controller
        control={control}
        name="role.company_permission"
        rules={{
          required: 'Permissão é obrigatório',
        }}
        render={({ field, fieldState: { error } }) => (
          <>
            <RadioGroup.Root
              mt="2"
              onValueChange={(value: RolePermission) => field.onChange(value)}
              defaultValue={field.value}
              value={field.value}
            >
              <Text as="label" mb="2">
                Permissão para Clientes
              </Text>

              <Flex gap="2" direction="row">
                <RadioGroup.Item value="NONE">Sem acesso</RadioGroup.Item>
                <RadioGroup.Item value="VIEWER">Visualizar</RadioGroup.Item>
                <RadioGroup.Item value="EDITOR">Editor</RadioGroup.Item>
              </Flex>
            </RadioGroup.Root>

            {error?.message && <InputError error={error.message} />}
          </>
        )}
      />

      <Controller
        control={control}
        name="role.user_permission"
        rules={{
          required: 'Permissão é obrigatório',
        }}
        render={({ field, fieldState: { error } }) => (
          <>
            <RadioGroup.Root
              mt="2"
              onValueChange={(value: RolePermission) => field.onChange(value)}
              defaultValue={field.value}
              value={field.value}
            >
              <Text as="label" mb="2">
                Permissão para Equipe
              </Text>

              <Flex gap="2" direction="row">
                <RadioGroup.Item value="NONE">Sem acesso</RadioGroup.Item>
                <RadioGroup.Item value="VIEWER">Visualizar</RadioGroup.Item>
                <RadioGroup.Item value="EDITOR">Editor</RadioGroup.Item>
              </Flex>
            </RadioGroup.Root>

            {error?.message && <InputError error={error.message} />}
          </>
        )}
      />

      <Controller
        control={control}
        name="role.task_permission"
        rules={{
          required: 'Permissão é obrigatório',
        }}
        render={({ field, fieldState: { error } }) => (
          <>
            <RadioGroup.Root
              mt="2"
              onValueChange={(value: RolePermission) => field.onChange(value)}
              defaultValue={field.value}
              value={field.value}
            >
              <Text as="label" mb="2">
                Permissão para Tarefas
              </Text>

              <Flex gap="2" direction="row">
                <RadioGroup.Item value="NONE">Sem acesso</RadioGroup.Item>
                <RadioGroup.Item value="VIEWER">Visualizar</RadioGroup.Item>
                <RadioGroup.Item value="EDITOR">Editor</RadioGroup.Item>
              </Flex>
            </RadioGroup.Root>

            {error?.message && <InputError error={error.message} />}
          </>
        )}
      />

      <Controller
        control={control}
        name="role.mailing_permission"
        rules={{
          required: 'Permissão é obrigatório',
        }}
        render={({ field, fieldState: { error } }) => (
          <>
            <RadioGroup.Root
              mt="2"
              onValueChange={(value: RolePermission) => field.onChange(value)}
              defaultValue={field.value}
              value={field.value}
            >
              <Text as="label" mb="2">
                Permissão para Mailing
              </Text>

              <Flex gap="2" direction="row">
                <RadioGroup.Item value="NONE">Sem acesso</RadioGroup.Item>
                <RadioGroup.Item value="VIEWER">Visualizar</RadioGroup.Item>
                <RadioGroup.Item value="EDITOR">Editor</RadioGroup.Item>
              </Flex>
            </RadioGroup.Root>

            {error?.message && <InputError error={error.message} />}
          </>
        )}
      />

      <Flex gap="4" mt="4">
        <Flex
          style={{
            flex: 1,
          }}
        >
          {data?.id && data?.name && (
            <AlertDialog.Root>
              <AlertDialog.Trigger>
                <Button color="crimson" variant="soft" type="button">
                  Excluir
                </Button>
              </AlertDialog.Trigger>
              <AlertDialog.Content maxWidth="450px">
                <AlertDialog.Title>Atenção</AlertDialog.Title>
                <AlertDialog.Description size="2">
                  Você está prestes a remover todo acesso de{' '}
                  <Strong>{user?.name}</Strong> tem certeza desta ação?
                </AlertDialog.Description>

                <Flex gap="3" mt="4" justify="end">
                  <AlertDialog.Cancel>
                    <Button variant="soft" color="gray">
                      Cancelar
                    </Button>
                  </AlertDialog.Cancel>
                  <AlertDialog.Action>
                    <Button
                      variant="solid"
                      color="red"
                      onClick={onDeleteUser}
                      loading={isPendingDeleteUser}
                    >
                      Excluir
                    </Button>
                  </AlertDialog.Action>
                </Flex>
              </AlertDialog.Content>
            </AlertDialog.Root>
          )}
        </Flex>

        <Flex justify="end">
          <Button size="3" type="submit" disabled={isLoading}>
            {isLoading && <Spinner />} Salvar
          </Button>
        </Flex>
      </Flex>

      <Toast ref={toastBC} />
    </form>
  );
};

export default UserAccessForm;
