import { useCallback, useState } from 'react';

import { Box, Flex, RadioGroup, Section, Text } from '@radix-ui/themes';
import { NavLink } from 'react-router-dom';

import TaskReferenceMonth from 'modules/tasks/components/TaskReferenceMonth';
import useTodos from 'modules/tasks/hooks/useTodos';

import Container from 'components/Container';
import IconLoading from 'components/IconLoading';
import InputSearch from 'components/InputSearch';

import { ROUTES } from 'config/routes';

import { Company } from 'services/companies/types';

import { FilterCategory } from './types';

const { v4: uuidv4 } = require('uuid');

const TasksBoard = () => {
  const [category, setCategory] = useState<Company['category']>(
    FilterCategory.Livre,
  );
  const [search, setSearch] = useState<string>();

  const { board, count, isPending } = useTodos(category, search);

  const onChangeFilter = useCallback(
    (value: FilterCategory) => {
      setCategory(value);
    },
    [setCategory],
  );

  return (
    <Container>
      {isPending && (
        <Flex justify="center">
          <Box>
            <IconLoading width="56px" height="56px" />
          </Box>
        </Flex>
      )}

      <Flex justify="between" mb="4">
        <Box mb="4">
          <InputSearch
            placeholder="Buscar cliente"
            onChange={(value) => setSearch(value)}
            total={count}
          />
        </Box>

        <RadioGroup.Root
          defaultValue={FilterCategory.Livre}
          onValueChange={onChangeFilter}
          disabled={isPending}
        >
          <Flex gap="2" direction="row">
            <Text as="label">Filtrar por:</Text>

            <Text as="label">
              <Flex gap="2" align="center">
                <RadioGroup.Item value={FilterCategory.Livre} /> Livre
              </Flex>
            </Text>
            <Text as="label">
              <Flex gap="2" align="center">
                <RadioGroup.Item value={FilterCategory.Varejista} /> Varejista
              </Flex>
            </Text>
          </Flex>
        </RadioGroup.Root>
      </Flex>

      {board.map((company) => (
        <Section key={uuidv4()} py="2">
          <Flex align="center">
            <Box style={{ width: 300 }}>
              <NavLink
                to={`/p/${ROUTES.PRIVATE.COMPANIES}/${company.id}`}
                target="_blank"
                style={{ textDecoration: 'none' }}
              >
                <Text color="blue">{company.trading_name}</Text>
              </NavLink>
            </Box>

            <Box>
              {company.tasks.map((task) => (
                <TaskReferenceMonth
                  key={uuidv4()}
                  tasks={task.data}
                  reference={task.reference}
                  company={company.trading_name}
                />
              ))}
            </Box>
          </Flex>
        </Section>
      ))}
    </Container>
  );
};

export default TasksBoard;
