export const ROUTES = {
  PUBLIC: {
    ROOT: '/',
    FORGOT_PASSWORD: 'forgot-password',
    CONFIRM_PASSWORD: 'create-password',
  },
  PRIVATE: {
    ROOT: 'p',
    DASHBOARD: 'dashboard',
    TASKS: 'tasks',
    TASK_DETAIL: 'tasks/:id',
    COMPANIES: 'companies',
    COMPANIES_CREATE: 'companies-create',
    COMPANIE: 'companies/:id',
    MAILING: 'mailing',
    CONTACTS: 'contacts',
    CONTACT_DETAIL: 'contacts/:id',
    STAFFS: 'staffs',
    STAFFS_CREATE: 'staffs-create',
    EMPLOYEE_DETAIL: 'staffs/:id',

    // Settings
    TEMPLATES_MAILS: 'templates',
    TEMPLATES_MAILS_FORM: 'templates/:id',
    TEMPLATES_MAILS_CREATE: 'templates-create',
  },
};
