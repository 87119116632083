import type { QueryKey } from '@tanstack/react-query';

import { StaffsParams } from './types';

export const createUseEmployee = (id: string): QueryKey => [`useEmployee`, id];

export const createUseStaffs = (params: StaffsParams): QueryKey => [
  `useStaffs`,
  params,
];
