import { useCallback } from 'react';

import { Box, IconButton, Tooltip } from '@radix-ui/themes';
import { useMatch, useNavigate } from 'react-router-dom';

import { MenuItemProps } from './types';

const MenuItem = ({ name, icon, path }: MenuItemProps) => {
  const match = useMatch(`/p/${path}`);
  const navitage = useNavigate();

  const handleNavigate = useCallback(() => {
    navitage(path);
  }, [navitage, path]);

  return (
    <Box>
      <Tooltip content={name}>
        <IconButton
          size="4"
          variant={match ? 'solid' : 'soft'}
          onClick={handleNavigate}
        >
          {icon}
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default MenuItem;
