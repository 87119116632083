import { useCallback, useRef, useState } from 'react';

import { AlertDialog, Button, Flex } from '@radix-ui/themes';
import { useQueryClient } from '@tanstack/react-query';
import { Toast } from 'primereact/toast';

import { useCompanyContactDelete } from 'services/companies';
import { createUseCompanyContacts } from 'services/companies/keys';

import { RemoveContactAlertProps } from './types';

const RemoveContactAlert = ({
  companyId,
  data: contact,
  trigger,
}: RemoveContactAlertProps) => {
  const [open, setOpen] = useState<boolean>();
  const toastBC = useRef<Toast>(null);

  const { mutate, isPending } = useCompanyContactDelete(companyId);
  const queryClient = useQueryClient();

  const handleRemoveContact = useCallback(() => {
    mutate(
      {
        id: contact.id,
      },
      {
        onSuccess() {
          toastBC.current?.show({
            severity: 'success',
            summary: 'Contato',
            detail: 'Contato removido com sucesso.',
            life: 5000,
          });

          queryClient.refetchQueries({
            queryKey: createUseCompanyContacts(companyId),
          });
        },
      },
    );
  }, [companyId, contact.id, mutate, queryClient]);

  return (
    <>
      <AlertDialog.Root onOpenChange={setOpen} open={open}>
        <AlertDialog.Trigger>{trigger}</AlertDialog.Trigger>
        <AlertDialog.Content maxWidth="450px">
          <AlertDialog.Title>Remover contato</AlertDialog.Title>
          <AlertDialog.Description size="2">
            Tem certeza de deseja remover <strong>{contact.name}</strong>?
          </AlertDialog.Description>

          <Flex gap="3" mt="4" justify="end">
            <AlertDialog.Cancel>
              <Button variant="soft" color="gray">
                Cancelar
              </Button>
            </AlertDialog.Cancel>
            <AlertDialog.Action>
              <Button
                variant="solid"
                color="red"
                onClick={handleRemoveContact}
                loading={isPending}
              >
                Excluir
              </Button>
            </AlertDialog.Action>
          </Flex>
        </AlertDialog.Content>
      </AlertDialog.Root>

      <Toast ref={toastBC} />
    </>
  );
};

export default RemoveContactAlert;
